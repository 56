import React from 'react'
import { CustomModal } from '@components'
import { Button, Typography } from 'antd'
import { removeHyperlinks } from '@helpers'
import PropTypes from 'prop-types'
import { printElement } from '@helpers'

const { Paragraph, Title } = Typography

const ItineraryModal = ({ handleCancel, handleSendItineraryEmail, sendItineraryEmailStatus, html, booking }) => {
  const handlePrint = () => {
    printElement(html)
  }

  return (
    <CustomModal
      className='sb-itinerary-modal'
      title={
        <div>
          <Title level={3}>Your Itinerary</Title>

          <Paragraph>View / Email & Print Your Itinerary</Paragraph>
        </div>
      }
      closable
      visible={true}
      width='100%'
      onCancel={handleCancel}
      footer={[
        <div key='footer'>
          <Button key='print' shape='round' size='large' onClick={handlePrint}>
            Print
          </Button>

          <Button
            key='email'
            size='large'
            type='primary'
            shape='round'
            loading={sendItineraryEmailStatus.loading}
            onClick={handleSendItineraryEmail}
          >
            Email
          </Button>
        </div>
      ]}
    >
      <div dangerouslySetInnerHTML={{ __html: removeHyperlinks(html) }} />
    </CustomModal>
  )
}

ItineraryModal.propTypes = {
  booking: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSendItineraryEmail: PropTypes.func.isRequired,
  html: PropTypes.string,
  sendItineraryEmailStatus: PropTypes.object.isRequired
}

export default ItineraryModal
