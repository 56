import React from 'react'
import { Typography } from 'antd'
import { Container } from '@components'
import { ReactComponent as Logo } from '@assets/parents-logo.svg'
import PropTypes from 'prop-types'
import './ErrorPage.less'

const { Title } = Typography

const ErrorPage = ({ extra, subTitle, title }) => (
  <div className='sb-error-page'>
    <Container>
      <Logo className='sb-error-page__logo' />

      <Title className='sb-error-page__title' level={1}>
        {title}
      </Title>

      <Title className='sb-error-page__subtitle' level={3}>
        {subTitle}
      </Title>

      {extra && <div className='sb-error-page__extra'>{extra}</div>}
    </Container>
  </div>
)

ErrorPage.propTypes = {
  extra: PropTypes.node,
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

ErrorPage.defaultProps = {
  extra: null
}

export default ErrorPage
