import React from 'react'
import { Badge, SimpleCard } from '@components'
import { Typography } from 'antd'
import { getEventStatus } from '@helpers'
import PropTypes from 'prop-types'

import './EventSummary.less'

const EventSummary = ({ event, loading }) => {
  const eventStatus = !loading ? getEventStatus(event.start_date, event.end_date) : null

  return (
    <SimpleCard color='secondary' loading={loading}>
      {!loading && (
        <React.Fragment>
          <Typography.Title className='sb-event-summary__title' level={3}>
            {event.client.name}
          </Typography.Title>

          <Typography.Title className='sb-event-summary__sub-title' level={4}>
            {event.title}{' '}
            <Badge className={`sb-event-summary__status`} type={eventStatus.type}>
              {eventStatus.label}
            </Badge>
          </Typography.Title>

          <Typography.Paragraph
            ellipsis={{ rows: 9, expandable: true, symbol: 'more' }}
            className='sb-event-summary__body'
          >
            {event.description}
          </Typography.Paragraph>
        </React.Fragment>
      )}
    </SimpleCard>
  )
}

EventSummary.propTypes = {
  event: PropTypes.object,
  loading: PropTypes.bool
}

export default EventSummary
