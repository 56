import { gql } from '@apollo/client'

export const BOOKING_SLOT_COMMENTS_QUERY = gql`
  query($id: ID!) {
    bookingSlot(id: $id) {
      id
      comments {
        id
        comment
        private
        created_at
        deleted_at
        updated_at
        user {
          id
          name
        }
      }
    }
  }
`

export default BOOKING_SLOT_COMMENTS_QUERY
