import React from 'react'
import { Select } from 'antd'
import { getFormattedSessionDateRange } from '@helpers'
import _orderBy from 'lodash/orderBy'
import PropTypes from 'prop-types'
import './SessionDropdown.less'

const { Option } = Select

const SessionDropdown = ({ handleSessionChange, selectedSession, sessions }) => (
  <div className='sb-session-dropdown'>
    <Select
      className='sb-session-dropdown__select'
      onChange={value => handleSessionChange(value)}
      value={selectedSession}
    >
      <Option value='all'>Show all sessions</Option>

      {_orderBy(sessions, ['starts_at']).map(session => {
        const sessionDateRange = getFormattedSessionDateRange(session.starts_at, session.ends_at)

        return (
          <Option value={session.id} key={session.id}>
            {session.title} <span className='sb-session-dropdown__option-date-range'>- ({sessionDateRange})</span>
          </Option>
        )
      })}
    </Select>
  </div>
)

SessionDropdown.propTypes = {
  handleSessionChange: PropTypes.func.isRequired,
  sessions: PropTypes.array.isRequired,
  selectedSession: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export default SessionDropdown
