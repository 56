import React from 'react'
import { Badge } from '@components'
import { Dropdown, Menu } from 'antd'

import {
  AppstoreAddOutlined,
  AppstoreOutlined,
  BellOutlined,
  SettingOutlined,
  UserAddOutlined
} from '@ant-design/icons'

import PropTypes from 'prop-types'

import './MobileEventMenu.less'

const MobileEventMenu = ({
  children,
  counts,
  handleEventActionClick,
  hasEventExpired,
  showChangeMeetingTypes,
  showGuestInvitations,
  showOutstandingInvitations,
  showSchedule
}) => (
  <Dropdown
    trigger={['click']}
    overlay={
      <Menu className='sb-mobile-event-menu' triggerSubMenuAction='click' theme='dark'>
        {showOutstandingInvitations && (
          <Menu.Item
            key='outstanding'
            icon={<AppstoreAddOutlined />}
            onClick={() => handleEventActionClick('outstanding')}
          >
            Outstanding Invitations
            {counts.outstanding > 0 && (
              <Badge type='info' className='sb-mobile-event-menu__badge'>
                {counts.outstanding}
              </Badge>
            )}
          </Menu.Item>
        )}

        {showSchedule && (
          <Menu.Item key='schedule' icon={<AppstoreOutlined />} onClick={() => handleEventActionClick('schedule')}>
            Your Bookings
            {counts.schedule > 0 && (
              <Badge type='info' className='sb-mobile-event-menu__badge'>
                {counts.schedule}
              </Badge>
            )}
          </Menu.Item>
        )}

        <Menu.Item key='notifications' icon={<BellOutlined />} onClick={() => handleEventActionClick('notifications')}>
          Notifications
          {counts.notifications > 0 && (
            <Badge type='info' className='sb-mobile-event-menu__badge'>
              {counts.notifications}
            </Badge>
          )}
        </Menu.Item>

        {showGuestInvitations && (
          <Menu.Item key='guests' icon={<UserAddOutlined />} onClick={() => handleEventActionClick('guests')}>
            Video Call Invitations
            {counts.guests > 0 && (
              <Badge type='info' className='sb-mobile-event-menu__badge'>
                {counts.guests}
              </Badge>
            )}
          </Menu.Item>
        )}

        {showChangeMeetingTypes && (
          <Menu.Item
            key='settings'
            icon={<SettingOutlined />}
            onClick={() => handleEventActionClick('changeMeetingType')}
            disabled={hasEventExpired}
          >
            Change Meeting Type
          </Menu.Item>
        )}
      </Menu>
    }
  >
    {children}
  </Dropdown>
)

MobileEventMenu.propTypes = {
  children: PropTypes.node,
  counts: PropTypes.object,
  handleEventActionClick: PropTypes.func,
  hasEventExpired: PropTypes.bool.isRequired,
  showChangeMeetingTypes: PropTypes.bool,
  showOutstandingInvitations: PropTypes.bool,
  showSchedule: PropTypes.bool,
  showGuestInvitations: PropTypes.bool
}

MobileEventMenu.defaultProps = {
  counts: {},
  showChangeMeetingTypes: false,
  showOutstandingInvitations: false,
  showSchedule: false,
  showGuestInvitations: false
}

export default MobileEventMenu
