import { BankOutlined, PhoneOutlined, VideoCameraOutlined } from '@ant-design/icons'

const getMeetingTypeIcon = type => {
  const icons = {
    VENUE: <BankOutlined />,
    PHONE: <PhoneOutlined />,
    VC: <VideoCameraOutlined />
  }

  return icons[type]
}

export default getMeetingTypeIcon
