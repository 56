import React from 'react'
import { Result } from 'antd'
import PropTypes from 'prop-types'

import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons'

const Progress = ({ progress }) => {
  const settings = {
    error: {
      icon: <CloseCircleOutlined />,
      status: 'error'
    },

    loading: {
      icon: <LoadingOutlined />,
      status: 'info'
    },

    success: {
      icon: <CheckCircleOutlined />,
      status: 'success'
    }
  }

  const { icon, status } = settings[progress.type]
  const { extraDetails = null, subTitle = null, title } = progress

  return <Result key={status} status={status} icon={icon} title={title} subTitle={subTitle} extra={extraDetails} />
}

export default Progress

Progress.propTypes = {
  progress: PropTypes.object.isRequired
}
