import React from 'react'
import { TerminalError } from '@components'
import { Button } from 'antd'

const UnauthorizedError = () => (
  <TerminalError
    title='Unauthorised'
    message='An error has occured. You appear not to have the correct permissions to access this resource.'
    extra={
      <Button href='/' type='primary' size='large' shape='round'>
        Take me Home
      </Button>
    }
  />
)

export default UnauthorizedError
