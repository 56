import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Form, Input, List, notification, Space, Typography } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Badge, CustomModal, EmptyMessage, SimpleCard } from '@components'
import { ReactComponent as Notes } from '@assets/notes.svg'
import { compareValues, getHumanTimeDuration } from '@helpers'
import { COMMENTS_CREATE_MUTATION, COMMENTS_DELETE_MUTATION, COMMENTS_UPDATE_MUTATION } from '@graphql'
import PropTypes from 'prop-types'

import './CommentsModal.less'

const { Paragraph, Title } = Typography

const CommentsModal = ({ commentableId, commentableType, comments, event, handleCancel, intro, title }) => {
  const [showEditCommentForm, setShowEditCommentForm] = useState(false)
  const [commentsList, setCommentsList] = useState(comments)
  const [deletingCommentIds, setDeletingCommentIds] = useState([])
  const [selectedComment, setSelectedComment] = useState(null)
  const [mode, setMode] = useState('new')

  const [mutateCreateComment, createCommentMutation] = useMutation(COMMENTS_CREATE_MUTATION, {
    onError: error => {
      notification.error({
        message: 'Error',
        description: `There was an error creating this comment. Please try again.`
      })
    },

    onCompleted: data => {
      setCommentsList([...commentsList, data.createComment])
      setShowEditCommentForm(false)
    }
  })

  const [mutateDeleteComment] = useMutation(COMMENTS_DELETE_MUTATION, {
    onCompleted: data => {
      const newComments = commentsList.filter(comment => comment.id !== data.deleteComment.id)

      setCommentsList(newComments)

      setDeletingCommentIds(deletingCommentIds.filter(id => id !== data.deleteComment.id))

      setShowEditCommentForm(false)
    },

    onError: error => {
      notification.error({
        message: 'Error',
        description: `There was an error deleting this comment. Please try again.`
      })
    }
  })

  const [mutateUpdateComment, updateCommentMutation] = useMutation(COMMENTS_UPDATE_MUTATION, {
    onError: error => {
      notification.error({
        message: 'Error',
        description: `There was an error updating this comment. Please try again.`
      })
    },

    onCompleted: data => {
      const filteredComments = commentsList.filter(comment => comment.id !== data.updateComment.id)

      const newComments = [...filteredComments, data.updateComment].sort(compareValues('created_at'))

      setCommentsList(newComments)
      setShowEditCommentForm(false)
    }
  })

  const handleCreateComment = () => {
    setMode('new')
    setSelectedComment({ comment: '' })
    setShowEditCommentForm(true)
  }

  const handleDeleteComment = id => {
    setDeletingCommentIds([...deletingCommentIds, id])
    mutateDeleteComment({ variables: { id } })
  }

  const handleEditComment = comment => {
    setMode('edit')
    setSelectedComment({ ...comment })
    setShowEditCommentForm(true)
  }

  const handleSaveComment = () => {
    mode === 'new'
      ? mutateCreateComment({
          variables: {
            input: {
              event_id: event.id,
              commentable_type: commentableType,
              commentable_id: commentableId,
              comment: selectedComment.comment,
              private: false
            }
          }
        })
      : mutateUpdateComment({
          variables: {
            id: selectedComment.id,
            input: {
              comment: selectedComment.comment,
              private: false
            }
          }
        })
  }

  const formFooter = (
    <Space
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <Button ghost onClick={() => setShowEditCommentForm(false)} shape='round'>
        Cancel
      </Button>

      <Button
        htmlType='submit'
        form='comments-form'
        type='primary'
        shape='round'
        loading={createCommentMutation.loading || updateCommentMutation.loading}
      >
        Save Comment
      </Button>
    </Space>
  )

  const standardFooter = (
    <Button
      icon={<PlusCircleOutlined />}
      size='large'
      type='primary'
      shape='round'
      onClick={() => handleCreateComment()}
    >
      New Meeting Note
    </Button>
  )

  const footer = showEditCommentForm ? formFooter : standardFooter

  return (
    <CustomModal
      className='sb-comments-modal'
      title={
        <div>
          <Title level={3}>{title}</Title>
          <Paragraph>{intro}</Paragraph>
        </div>
      }
      closable
      visible={true}
      onCancel={handleCancel}
      width='100%'
      footer={(commentsList.length > 0 || showEditCommentForm) && footer}
    >
      {!showEditCommentForm && commentsList.length === 0 && (
        <SimpleCard color='transparent'>
          <EmptyMessage
            title='Meeting notes are empty'
            image={<Notes />}
            description='You currently have no meeting notes. Click the button below to send the host a message concerning talking points for the meeting.'
          >
            <Button type='primary' shape='round' size='large' onClick={() => handleCreateComment()}>
              Create Meeting Note
            </Button>
          </EmptyMessage>
        </SimpleCard>
      )}

      {!showEditCommentForm && commentsList.length > 0 && (
        <React.Fragment>
          <List
            dataSource={commentsList}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  description={
                    <div className='sb-comments-modal__summary'>
                      <div className='sb-comments-modal__summary-title'>
                        <Badge type='success'>Created: {getHumanTimeDuration(item.created_at)} ago</Badge>

                        {item.updated_at !== item.created_at && (
                          <Badge type='info'>Modified: {getHumanTimeDuration(item.updated_at)} ago</Badge>
                        )}
                      </div>

                      <div className='sb-comments-modal__summary-note'>{item.comment}</div>

                      <div className='sb-comments-modal__summary-actions'>
                        <Button
                          type='link'
                          size='small'
                          key='delete'
                          onClick={() => handleDeleteComment(item.id)}
                          loading={deletingCommentIds.includes(item.id)}
                        >
                          Delete
                        </Button>

                        <Button type='link' size='small' key='edit' onClick={() => handleEditComment(item)}>
                          Edit
                        </Button>
                      </div>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </React.Fragment>
      )}

      {showEditCommentForm && (
        <div className='sb-comments-modal__form'>
          <Paragraph className='sb-comments-modal__form-intro'>Please add / edit your comment below.</Paragraph>

          <Form id='comments-form' onFinish={() => handleSaveComment()}>
            <Form.Item
              name='comment'
              initialValue={selectedComment.comment}
              rules={[{ required: true, message: 'Please add a comment' }]}
            >
              <Input.TextArea
                value={selectedComment.comment}
                onChange={e =>
                  setSelectedComment({
                    ...selectedComment,
                    comment: e.target.value
                  })
                }
                autoFocus
                rows={15}
                showCount
                maxLength={500}
              />
            </Form.Item>
          </Form>
        </div>
      )}
    </CustomModal>
  )
}

CommentsModal.propTypes = {
  commentableId: PropTypes.string.isRequired,
  commentableType: PropTypes.string.isRequired,
  comments: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
}

export default CommentsModal
