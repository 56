import React from 'react'
import { Button, Typography } from 'antd'
import { Badge, IconItem } from '@components'
import { BookOutlined, CalendarOutlined, FormOutlined, UserOutlined } from '@ant-design/icons'

import {
  getFormattedSessionDateRange,
  getGroupLessonNote,
  getLessonSubject,
  getLocationName,
  getStudentFullname,
  hasSessionExpired
} from '@helpers'

import { meetingTypes } from '@config'
import PropTypes from 'prop-types'

import './InvitationCard.less'

const { Paragraph, Title } = Typography

const InvitationCard = ({ event, invitation, invitationGroup, handleInvitationGroupChange, session }) => {
  const getStatusBadge = () => {
    if (hasExpired) return <Badge type='info'>Expired</Badge>
    if (availableSlotCount === 0) return <Badge type='danger'>Fully Booked</Badge>

    return <Badge type='success'>{availableSlotCount} Slots</Badge>
  }

  const { lesson, group, student } = invitationGroup
  const { groupLessonNotes, location } = group
  const { availableSlotCount } = group
  const sessionDateRange = getFormattedSessionDateRange(session.starts_at, session.ends_at)
  const lessonSubject = getLessonSubject(lesson)
  const studentName = getStudentFullname(student)
  const locationName = getLocationName(invitation, location)
  const groupLessonNote = getGroupLessonNote(groupLessonNotes, lesson)
  const hasExpired = hasSessionExpired(session.ends_at, 12)
  const isDisabled = event.disable_parent_bookings
  const showBookButton = !hasExpired && !isDisabled
  const statusBadge = getStatusBadge(hasExpired, availableSlotCount)

  return (
    <div className='sb-invitation-card'>
      <div className='sb-invitation-card__heading'>
        <div className='sb-invitation-card__title'>
          <Title level={4}>{group.title}</Title>
        </div>

        {/** Book button for screens > 480px */}
        <div className='sb-invitation-card__controls'>
          <div className='sb-invitation-card__status'>{statusBadge}</div>

          {showBookButton && availableSlotCount > 0 && (
            <Button
              className='sb-invitation-card__book-button'
              type='primary'
              shape='round'
              size='large'
              onClick={() => handleInvitationGroupChange(invitationGroup)}
            >
              Book Slot
            </Button>
          )}
        </div>
      </div>

      <div className='sb-invitation-card__content'>
        <div className='sb-invitation-card__status--mobile'>{statusBadge}</div>

        <div className='sb-invitation-card__date'>
          <IconItem text={sessionDateRange} icon={<CalendarOutlined />} />
        </div>

        <div className='sb-invitation-card__subject'>
          <IconItem text={lessonSubject} icon={<BookOutlined />} />
        </div>

        <div className='sb-invitation-card__student'>
          <IconItem text={studentName} icon={<UserOutlined />} />
        </div>

        {locationName && (
          <div className='sb-invitation-card__location'>
            <IconItem text={locationName} icon={meetingTypes.VENUE.icon} />
          </div>
        )}

        {groupLessonNote !== null && (
          <div className='sb-invitation-card__notes'>
            <IconItem text='Notes from the teacher' icon={<FormOutlined />} />
            <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>{groupLessonNote.note}</Paragraph>
          </div>
        )}

        {/** Book button for small screens */}
        {showBookButton && availableSlotCount > 0 && (
          <Button
            className='sb-invitation-card__book-button--mobile'
            type='primary'
            shape='round'
            size='large'
            onClick={() => handleInvitationGroupChange(invitationGroup)}
            block
          >
            Book Slot
          </Button>
        )}
      </div>
    </div>
  )
}

InvitationCard.propTypes = {
  event: PropTypes.object.isRequired,
  handleInvitationGroupChange: PropTypes.func.isRequired,
  invitation: PropTypes.object.isRequired,
  invitationGroup: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired
}

export default InvitationCard
