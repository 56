import React from 'react'
import { Typography } from 'antd'
import PropTypes from 'prop-types'

import './Loader.less'

const Loader = ({ message }) => (
  <div className='sb-loader'>
    <Typography.Title className='sb-loader__title'>{message}</Typography.Title>

    <div className='sb-loader__animation'>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
)

Loader.propTypes = {
  message: PropTypes.string
}

Loader.defaultProps = {
  message: 'Please wait loading ...'
}

export default Loader
