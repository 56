import React from 'react'
import PropTypes from 'prop-types'
import './Badge.less'

const Badge = ({ children, className, size, type }) => (
  <span className={`${className} sb-badge sb-badge--${size} sb-badge--${type}`}>{children}</span>
)

Badge.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['danger', 'error', 'info', 'info-dark', 'success', 'warning'])
}

Badge.defaultProps = {
  className: '',
  size: 'medium',
  type: 'info'
}

export default Badge
