import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

const hasSameUTCOffset = (timezone, comparisonTimezone) =>
  dayjs.tz(dayjs(), timezone).utcOffset() === dayjs.tz(dayjs(), comparisonTimezone).utcOffset()

export default hasSameUTCOffset
