import React from 'react'
import { Empty, Typography } from 'antd'
import PropTypes from 'prop-types'
import defaultEmptyImage from '@assets/empty.svg'

import './EmptyMessage.less'

const { Text, Title } = Typography

const EmptyMessage = ({ children, description, image, title }) => (
  <Empty
    className='sb-empty-message'
    image={image}
    description={
      <div>
        <Title className='sb-empty-message__title' level={4}>
          {title}
        </Title>
        <Text className='sb-empty-message__description'>{description}</Text>
      </div>
    }
  >
    {children && children}
  </Empty>
)

EmptyMessage.propTypes = {
  children: PropTypes.node,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string.isRequired,
  image: PropTypes.node
}

EmptyMessage.defaultProps = {
  children: null,
  image: defaultEmptyImage
}

export default EmptyMessage
