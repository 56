import { gql } from '@apollo/client'

export const COMMENTS_FIELDS_FRAGMENT = gql`
  fragment CommentFields on Comment {
    id
    comment
    created_at
    updated_at
    user {
      id
      name
    }
  }
`
