import { toDayJs } from '@helpers'

const getFormattedSessionDateRange = (starts, ends) => {
  const startDate = toDayJs(starts)
  const endDate = toDayJs(ends)

  if (startDate.isSame(ends, 'day')) {
    return `${startDate.format('ddd D MMM YYYY')} ${startDate.format(
      'H:mm'
    )} - ${endDate.format('H:mm')}`
  }

  return `${startDate.format('ddd D MMM')} - ${endDate.format(
    'ddd D MMM YYYY'
  )}`
}

export default getFormattedSessionDateRange
