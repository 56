import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { getHumanTimeDuration, toDayJs } from '@helpers'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

const getSuffix = (startDate, today) => {
  if (startDate > today) return ''

  return 'ago'
}

const getDurationToEvent = (starts, ends) => {
  const today = toDayJs()
  const startDate = toDayJs(starts)
  const endDate = toDayJs(ends)

  if (
    startDate.isSameOrBefore(today, 'day') &&
    endDate.isSameOrAfter(today, 'day')
  ) {
    return 'Today'
  }

  return getHumanTimeDuration(startDate) + ' ' + getSuffix(startDate, today)
}

export default getDurationToEvent
