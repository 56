import React from 'react'
import { Typography } from 'antd'
import { Badge, IconItem } from '@components'
import { getDurationToEvent, getEventStatus, getFormattedEventDateRange } from '@helpers'
import { ArrowRightOutlined, CalendarOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

import './EventPickerCard.less'

const { Paragraph, Title } = Typography

const EventPickerCard = ({ handleSetEvent, event, live }) => {
  const durationTillEvent = getDurationToEvent(event.start_date, event.end_date)
  const dateRange = getFormattedEventDateRange(event.start_date, event.end_date, live)
  const eventStatus = getEventStatus(event.start_date, event.end_date)
  const durationPrefix =
    eventStatus.label !== 'Current' ? (eventStatus.label === 'Expired' ? 'Expired ' : 'Upcoming in ') : ''

  return (
    <div
      className={`sb-event-picker-card sb-event-picker-card--${eventStatus.label.toLowerCase()}`}
      onClick={() => handleSetEvent(event.id)}
    >
      <div className='sb-event-picker-card__body'>
        <div className='sb-event-picker-card__title'>
          <Title level={5}>{event.title}</Title>

          <Badge size='small' type={eventStatus.type}>
            {durationPrefix}
            {durationTillEvent}
          </Badge>
        </div>

        <IconItem className='sb-event-picker-card__date' icon={<CalendarOutlined />} text={dateRange} />

        {live && (
          <Paragraph className='sb-event-picker-card__description' ellipsis={{ rows: 4 }}>
            {event.description}
          </Paragraph>
        )}
      </div>

      <div className='sb-event-picker-card__action'>
        <ArrowRightOutlined />
      </div>
    </div>
  )
}

EventPickerCard.propTypes = {
  handleSetEvent: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  live: PropTypes.bool.isRequired
}

export default EventPickerCard
