import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client'

import * as Sentry from '@sentry/react'
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { authService } from '@services'

let client
let persistor

const setup = async () => {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT
  })

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.message) {
          case 'Unauthenticated.':
            authService.logout(true)
            window.location.href = '/session-expired'
            break
          default:
            Sentry.withScope(function (scope) {
              scope.setLevel('info')
              scope.setExtra('GraphQL response', JSON.stringify(err))
              Sentry.captureException(new Error(err.message))
            })
        }
      }
    }
  })

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token')
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : ''
      }
    }
  })

  const cache = new InMemoryCache()

  persistor = await new CachePersistor({
    cache,
    storage: new LocalStorageWrapper(window.localStorage)
  })

  return new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache,
    connectToDevTools: process.env.REACT_APP_ENV === 'local'
  })
}

export const getPersistor = () => {
  return persistor
}

const getClient = () => {
  if (!client) {
    client = setup()
  }

  return client
}

export default getClient
