import dayjs from 'dayjs'
import { toDayJs } from '@helpers'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(isBetween)

const getEventStatus = (starts, ends) => {
  const startDate = toDayJs(starts)
  const endDate = toDayJs(ends)
  const today = dayjs()

  if (today.isBetween(startDate, endDate, 'day', '[]')) {
    return { label: 'Current', type: 'success' }
  }

  if (today.isBefore(startDate, 'day')) {
    return { label: 'Upcoming', type: 'warning' }
  }

  if (today.isAfter(endDate, 'day')) {
    return { label: 'Expired', type: 'info' }
  }
}

export default getEventStatus
