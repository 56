import { toDayJs } from '@helpers'

const getSecondsToSessionStart = sessions => {
  let secondsToSessionStart = null

  sessions.forEach(session => {
    const secondsToStart = toDayJs().diff(toDayJs(session.starts_at), 'seconds')

    if (secondsToStart <= 0 && (secondsToSessionStart === null || secondsToStart > secondsToSessionStart)) {
      secondsToSessionStart = Math.abs(secondsToStart)
    }
  })

  return secondsToSessionStart
}

export default getSecondsToSessionStart
