import { gql } from '@apollo/client'

const bookingFields = `
  id
  
  bookingSlots {
    id  
    lesson {
      id
      name

      teacher {
        id
        salutation
        forename
        surname
      }

      subject {
        id
        name
      }
    }

    slot {
      id
      starts_at
      ends_at
      session_id

      group {
        id
        title
        availableSlotCount
        
        groupLessonNotes {
          id
          lesson_id
          note
        }

        location {
          id
          name
        }
      }
    }

    student {
      id
      forename
      surname
    }
  }
`

const eventFields = `
  id
  title
  description
  start_date
  end_date
  disable_parent_bookings
  vc_auto_disconnect
  max_bookings_per_student
  
  client {
    id
    name
    external_ref
    timezone
  }

  sessions (orderBy: [{ column: STARTS_AT, order: ASC }]) {
    id
    title
    starts_at
    ends_at
  }

  meetingTypes {
    id
    name
    type
  }

  invitations {
    id
    event_id

    meetingType {
      id
      name
      type
    }

    user {
      id
      name
      email
      mobile
    }

    invitationGroups {
      id
      group {
        id
        title
        availableSlotCount
                
        location {
          id
          name
        }

        groupLessonNotes {
          id
          lesson_id
          note
        }

        sessions {
          id
        }
      }

      lesson {
        id
        name
        subject {
          id
          name
        }
      }

      student {
        id
        forename
        surname
      }
    }
  }
`
export const PARENTS_MEETING_EVENT_FIELDS_FRAGMENT = gql`
  fragment EventFields on Event {
    ${eventFields}
  }
`

export const PARENTS_MEETING_GUEST_EVENT_FIELDS_FRAGMENT = gql`
  fragment GuestEventFields on GuestEvent {
    ${eventFields}
  }
`

export const PARENTS_MEETING_BOOKING_FIELDS_FRAGMENT = gql`
  fragment BookingFields on Booking {
    ${bookingFields}
  }
`

export const PARENTS_MEETING_GUEST_BOOKING_FIELDS_FRAGMENT = gql`
  fragment GuestBookingFields on GuestBooking {
    ${bookingFields}
  }
`
