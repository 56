const notificationMp3 =
  'Audio' in window ? new Audio('/notification.mp3') : null

const notificationAudio = {
  play: () => {
    if (notificationMp3) {
      notificationMp3.play().catch(() => console.log('Play audio error'))
    }
  }
}

export default notificationAudio
