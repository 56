import React from 'react'
// import { Radio } from 'antd'
import { SessionDropdown } from '@components'
// import { AppstoreOutlined, UnorderedListOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import './SessionControls.less'

const SessionControls = ({ currentDisplay, currentSession, handleSetDisplay, handleSessionChange, sessions }) => (
  <div className='sb-session-controls'>
    <div className='sb-session-controls__filter'>
      <SessionDropdown
        sessions={sessions}
        selectedSession={currentSession}
        handleSessionChange={value => handleSessionChange(value)}
      />
    </div>

    {/**
    <div className='sb-session-controls__display'>
      <Radio.Group defaultValue={currentDisplay} buttonStyle='solid'>
        <Radio.Button value='grid' onClick={() => handleSetDisplay('grid')}>
          <AppstoreOutlined />
        </Radio.Button>

        <Radio.Button value='list' onClick={() => handleSetDisplay('list')}>
          <UnorderedListOutlined />
        </Radio.Button>
      </Radio.Group>
    </div>
    */}
  </div>
)

SessionControls.propTypes = {
  currentDisplay: PropTypes.string.isRequired,
  currentSession: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleSetDisplay: PropTypes.func.isRequired,
  handleSessionChange: PropTypes.func.isRequired
}

export default SessionControls
