import { gql } from '@apollo/client'

export const GUEST_INVITATION_FIELDS_FRAGMENT = gql`
  fragment GuestInvitationFields on GuestInvitation {
    id
    email
    name
    created_at
    students {
      id
      forename
      surname
    }
  }
`
