import React from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types'
import './CustomModal.less'

const CustomModal = ({ children, className, ...props }) => (
  <Modal className={`sb-custom-modal ${className}`} {...props}>
    {children}
  </Modal>
)

CustomModal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

CustomModal.defaultProps = {
  className: ''
}

export default CustomModal
