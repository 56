const printElement = html => {
  const iframe = document.createElement('iframe')
  iframe.style.display = 'none'
  document.body.appendChild(iframe)
  const printWindow = iframe.contentWindow
  printWindow.document.open()
  printWindow.document.write(html)
  printWindow.document.close()
  printWindow.focus()
  printWindow.print()
  printWindow.onafterprint = () => {
    document.body.removeChild(iframe)
  }
}

export default printElement
