import React, { useRef, useState } from 'react'
import { Badge, EmptyMessage, EventPickerCard, SimpleCard } from '@components'
import { ReactComponent as NoResults } from '@assets/search.svg'
import { Button, Input, Modal, Pagination, Typography } from 'antd'
import { isEventLive, isEventUpcoming } from '@helpers'
import _orderBy from 'lodash/orderBy'
import PropTypes from 'prop-types'

import './EventPickerModal.less'

const { Search } = Input
const { Paragraph, Title } = Typography
const eventsPerPage = 5

const getPaginatedEvents = (currentPage, events) => {
  const startPos = currentPage === 1 ? 0 : (currentPage - 1) * eventsPerPage
  return events.slice(startPos, startPos + eventsPerPage)
}

const EventPickerModal = ({ events, handleCancel, handleSetEvent }) => {
  const searchRef = useRef(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')

  const handleClearSearch = () => {
    setSearch('')
    searchRef.current.focus()
  }

  const handleSearch = search => {
    setCurrentPage(1)
    setSearch(search)
  }

  const counts = events.reduce(
    (counts, event) => {
      if (isEventLive(event)) {
        counts['live']++
      } else if (isEventUpcoming(event)) {
        counts['upcoming']++
      } else {
        counts['past']++
      }

      return counts
    },
    { live: 0, past: 0, upcoming: 0 }
  )

  const filteredEvents = events
    .map(event => ({ ...event, live: isEventLive(event) }))
    .filter(event => search.length === 0 || event.title.toLowerCase().includes(search.toLowerCase()))

  const orderedFilteredEvents = _orderBy(filteredEvents, ['live', 'event.start_date'], ['desc'])

  return (
    <Modal
      className='sb-event-picker-modal'
      closable={false}
      footer={null}
      onCancel={handleCancel}
      visible={true}
      title={
        <div className='sb-event-picker-modal__header'>
          <Title level={3}>Select an Event</Title>

          <Paragraph>Please select one of the events below.</Paragraph>

          <div className='sb-event-picker-modal__summary'>
            <Badge type='success' size='large'>
              {counts.live} Current <span className='hidden-xs'>Events</span>
            </Badge>

            <Badge type='warning' size='large'>
              {counts.upcoming} Upcoming <span className='hidden-xs'>Events</span>
            </Badge>

            <Badge type='info' size='large'>
              {counts.past} Past <span className='hidden-xs'>Events</span>
            </Badge>
          </div>
        </div>
      }
    >
      <Search
        className='sb-event-picker-modal__search'
        ref={searchRef}
        size='large'
        placeholder='Search for an event'
        allowClear
        onChange={e => handleSearch(e.target.value)}
        value={search}
      />

      {orderedFilteredEvents.length === 0 && (
        <SimpleCard color='quaternary'>
          <EmptyMessage
            title='No events found'
            image={<NoResults />}
            description='No events can be found. Try clearing your filters.'
          >
            <Button className='cta' type='primary' shape='round' size='large' onClick={() => handleClearSearch()}>
              Clear Filters
            </Button>
          </EmptyMessage>
        </SimpleCard>
      )}

      {getPaginatedEvents(currentPage, orderedFilteredEvents).map(event => (
        <EventPickerCard
          key={event.id}
          event={event}
          handleSetEvent={eventId => handleSetEvent(eventId)}
          live={event.live}
        />
      ))}

      {orderedFilteredEvents.length > eventsPerPage && (
        <Pagination
          current={currentPage}
          className='sb-event-picker-modal__pagination'
          onChange={currentPage => setCurrentPage(currentPage)}
          defaultCurrent={currentPage}
          total={orderedFilteredEvents.length}
          defaultPageSize={eventsPerPage}
        />
      )}
    </Modal>
  )
}

EventPickerModal.propTypes = {
  events: PropTypes.array.isRequired,
  handleCancel: PropTypes.func,
  handleSetEvent: PropTypes.func.isRequired
}

EventPickerModal.defaultProps = {
  handleCancel: () => {}
}

export default EventPickerModal
