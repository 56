import React from 'react'
import { Typography } from 'antd'
import PropTypes from 'prop-types'

import './TickerTape.less'

const { Title } = Typography

const TickerTape = ({ children, title }) => (
  <div className={`sb-ticker-tape`}>
    <Title level={4} className='sb-ticker-tape__title'>
      {title}
    </Title>
    <div className='sb-ticker-tape__body'>{children}</div>
  </div>
)

TickerTape.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
}

TickerTape.defaultProps = {}

export default TickerTape
