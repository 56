import React from 'react'
import { Skeleton, Typography } from 'antd'
import PropTypes from 'prop-types'

import './SimpleCard.less'

const SimpleCard = ({ action, badge, children, className, color, header, loading, strapline, title }) => (
  <div className={`sb-simple-card sb-simple-card--${color} ${className}`}>
    {loading && <Skeleton active />}

    {!loading && (
      <React.Fragment>
        {header && <div className={`sb-simple-card__header sb-simple-card__header--${color}`}>{header}</div>}

        {title && (
          <div className='sb-simple-card__title'>
            <Typography.Title level={3}>
              {title}
              {badge && <span className='sb-simple-card__title-badge'>{badge}</span>}
            </Typography.Title>

            {action && <span className='sb-simple-card__title-action'>{action}</span>}
          </div>
        )}

        {strapline && <Typography.Paragraph className='sb-simple-card__strapline'>{strapline}</Typography.Paragraph>}

        {children}
      </React.Fragment>
    )}
  </div>
)

SimpleCard.propTypes = {
  action: PropTypes.node,
  badge: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'quaternary',
    'quinary',
    'senary',
    'default',
    'transparent'
  ]),
  header: PropTypes.node,
  loading: PropTypes.bool,
  title: PropTypes.string,
  strapline: PropTypes.string,
  theme: PropTypes.string
}

SimpleCard.defaultProps = {
  action: null,
  badge: null,
  className: '',
  color: 'default',
  header: null,
  loading: false,
  title: null,
  strapline: null
}

export default SimpleCard
