import React from 'react'
import { EventsList } from '@components'
import { isEventLive, isEventPast, isEventUpcoming } from '@helpers'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'

import './SidebarMenu.less'

const SidebarMenu = ({ activeEvent, events, handleEventChange, handleShowMoreEvents, eventsListPerPage, loading }) => {
  const orderedEvents = orderBy(events, ['start_date'], ['desc'])
  const currentEvents = orderedEvents.filter(event => isEventLive(event))
  const upcomingEvents = orderedEvents.filter(event => !isEventLive(event) && isEventUpcoming(event))
  const pastEvents = orderedEvents.filter(event => isEventPast(event))

  return (
    <div className='sb-sidebar-menu'>
      <EventsList
        activeEvent={activeEvent}
        className='sb-events-list'
        events={currentEvents}
        handleEventChange={eventId => handleEventChange(eventId)}
        handleShowMoreEvents={() => handleShowMoreEvents('current')}
        loading={loading}
        perPage={eventsListPerPage.current}
        title='Active Events'
        emptyMessage='You currently have no active events.'
      />

      <EventsList
        activeEvent={activeEvent}
        className='sb-events-list'
        events={upcomingEvents}
        handleEventChange={eventId => handleEventChange(eventId)}
        handleShowMoreEvents={() => handleShowMoreEvents('current')}
        loading={loading}
        perPage={eventsListPerPage.current}
        title='Upcoming Events'
        emptyMessage='You currently have no upcoming events.'
      />

      <EventsList
        activeEvent={activeEvent}
        className='sb-events-list'
        events={pastEvents}
        handleEventChange={eventId => handleEventChange(eventId)}
        handleShowMoreEvents={() => handleShowMoreEvents('past')}
        loading={loading}
        perPage={eventsListPerPage.past}
        title='Past Events'
        emptyMessage='You currently have no past events.'
      />
    </div>
  )
}

SidebarMenu.props = {
  activeEvent: PropTypes.object,
  events: PropTypes.array,
  handleEventChange: PropTypes.func,
  handleShowMoreEvents: PropTypes.func,
  eventsListPerPage: PropTypes.object,
  loading: PropTypes.bool
}

SidebarMenu.defaultProps = {
  activeEvent: null,
  events: [],
  eventsListPerPage: {},
  handleEventChange: () => {},
  handleShowMoreEvents: () => {},
  loading: false
}

export default SidebarMenu
