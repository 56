import React from 'react'
import { Button, Menu, Typography } from 'antd'
import { IconItem } from '@components'

import {
  BookOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  MoreOutlined,
  VideoCameraOutlined,
  FormOutlined,
  TeamOutlined,
  UserOutlined
} from '@ant-design/icons'

import {
  getFormattedSlotTime,
  getLessonSubject,
  getLocationName,
  getStudentFullname,
  hasEventExpired,
  toDayJs
} from '@helpers'

import { meetingTypes } from '@config'
import PropTypes from 'prop-types'

import './SlotCard.less'

const { Paragraph, Title } = Typography

const SlotCard = ({
  event,
  group,
  handleShowVideoConferenceModal,
  invitation,
  lesson,
  location,
  note,
  slot,
  student,
  handleUnbookSlot,
  handleShowComments,
  isLoadingComments,
  isGuest,
  isUnbookingSlot,
  showVideoConference,
  vcParent
}) => {
  const lessonSubject = getLessonSubject(lesson)
  const studentName = getStudentFullname(student)
  const locationName = getLocationName(invitation, location)
  const starts = toDayJs(slot.starts_at)
  const slotTime = getFormattedSlotTime(slot.starts_at, slot.ends_at)
  const hasExpired = hasEventExpired(slot.ends_at, 12)
  const isLoading = isLoadingComments || isUnbookingSlot

  return (
    <div className='sb-slot-card'>
      <div className='sb-slot-card__day'>
        <Title level={1}>{starts.format('DD')}</Title>
        <Title level={3}>{starts.format('MMM')}</Title>
      </div>

      <div className='sb-slot-card__content'>
        <div style={{ width: '100%' }}>
          <div className='sb-slot-card__heading'>
            <div className='sb-slot-card__time'>
              <Title level={5}>{slotTime}</Title>
            </div>

            {!isGuest && (
              <div className='sb-slot-card__controls'>
                <Menu mode='horizontal' selectable={false} theme='dark' triggerSubMenuAction='click'>
                  <Menu.SubMenu key='SubMenu' icon={isLoading ? <LoadingOutlined /> : <MoreOutlined />}>
                    <Menu.Item
                      className='sb-slot-card__item'
                      key='two'
                      icon={<CloseCircleOutlined />}
                      disabled={hasExpired}
                      onClick={() => handleUnbookSlot(slot)}
                    >
                      Cancel Booking
                    </Menu.Item>

                    <Menu.Item
                      className='sb-slot-card__item'
                      key='three'
                      icon={<FormOutlined />}
                      onClick={() => handleShowComments()}
                    >
                      Meeting Notes
                    </Menu.Item>

                    {showVideoConference && (
                      <Menu.Item
                        className='sb-slot-card__item'
                        key='four'
                        icon={<VideoCameraOutlined />}
                        disabled={hasExpired}
                        onClick={() => handleShowVideoConferenceModal(slot, group, lesson, student)}
                      >
                        Start Video Call
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                </Menu>
              </div>
            )}
          </div>

          <div className='sb-slot-card__group'>
            <IconItem text={group.title} icon={<TeamOutlined />} />
          </div>

          <div className='sb-slot-card__subject'>
            <IconItem text={lessonSubject} icon={<BookOutlined />} />
          </div>

          <div className='sb-slot-card__student'>
            <IconItem text={studentName} icon={<UserOutlined />} />
          </div>

          {locationName && (
            <div className='sb-slot-card__location'>
              <IconItem text={locationName} icon={meetingTypes.VENUE.icon} />
            </div>
          )}

          {note !== null && (
            <div className='sb-slot-card__notes'>
              <IconItem text='Notes from the teacher' icon={<FormOutlined />} />
              <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>{note.note}</Paragraph>
            </div>
          )}

          {showVideoConference && !hasExpired && (
            <div className='sb-slot-card__cta'>
              <Button
                className='sb-slot-card__vc-button'
                onClick={() => handleShowVideoConferenceModal(slot, group, lesson, student)}
                type='primary'
                shape='round'
                size='large'
                icon={<VideoCameraOutlined />}
                block
                disabled={hasExpired}
              >
                Start Video Call
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

SlotCard.propTypes = {
  group: PropTypes.object.isRequired,
  lesson: PropTypes.object.isRequired,
  location: PropTypes.object,
  handleShowComments: PropTypes.func.isRequired,
  handleShowVideoConferenceModal: PropTypes.func.isRequired,
  handleUnbookSlot: PropTypes.func.isRequired,
  invitation: PropTypes.object.isRequired,
  isGuest: PropTypes.bool,
  isLoadingComments: PropTypes.bool.isRequired,
  isUnbookingSlot: PropTypes.bool,
  note: PropTypes.object,
  slot: PropTypes.object.isRequired,
  showVideoConference: PropTypes.bool.isRequired,
  student: PropTypes.object.isRequired,
  vcParent: PropTypes.object.isRequired
}

SlotCard.defaultProps = {
  isGuest: false,
  location: null
}

export default SlotCard
