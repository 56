import React from 'react'
import { SimpleCard } from '@components'
import { Typography } from 'antd'
import { ReactComponent as GenericError } from '@assets/generic-error.svg'
import PropTypes from 'prop-types'
import './TerminalError.less'

const { Paragraph, Title } = Typography

const TerminalError = ({ title, message, extra }) => {
  return (
    <SimpleCard color='secondary' className='sb-terminal-error'>
      <GenericError className='sb-terminal-error__image' />

      <Title className='sb-terminal-error__title' level={2}>
        {title}
      </Title>

      <Paragraph className='sb-terminal-error__text'>{message}</Paragraph>

      <div className='sb-terminal-error__extra'>{extra && extra}</div>
    </SimpleCard>
  )
}

TerminalError.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  extra: PropTypes.node
}

TerminalError.defaultProps = {
  extra: null
}

export default TerminalError
