import React from 'react'
import { Spin } from 'antd'
import PropTypes from 'prop-types'

const Header = ({ extra, loading, title }) => {
  if (loading) {
    return (
      <div className='header'>
        <Spin />
      </div>
    )
  }

  return (
    <div className='header'>
      <div className='title'>{title}</div>
      <div className='extra'>{extra}</div>
    </div>
  )
}

Header.propTypes = {
  extra: PropTypes.node,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

export default Header
