import React from 'react'
import PropTypes from 'prop-types'
import './RoundBadge.less'

const RoundBadge = ({ children, className, size, type }) => (
  <span className={`${className} sb-round-badge sb-round-badge--${size} sb-round-badge--${type}`}>{children}</span>
)

RoundBadge.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['danger', 'error', 'info', 'success', 'warning'])
}

RoundBadge.defaultProps = {
  className: '',
  type: 'info'
}

export default RoundBadge
