import React, { useState } from 'react'
import { Button } from 'antd'
import { Alert, Badge, EmptyMessage, SessionCard, SessionControls, SimpleCard, SlotCard } from '@components'
import { Link } from 'react-router-dom'
import { ReactComponent as NoSchedule } from '@assets/schedule.svg'
import { getGroupLessonNote } from '@helpers'
import _groupBy from 'lodash/groupBy'
import _orderBy from 'lodash/orderBy'
import PropTypes from 'prop-types'

import './Schedule.less'

const Schedule = ({
  booking,
  bookingSlotCommentsLoading,
  invitation,
  event,
  handleChangeTab,
  handleSelectedSessionChange,
  handleShowComments,
  handleShowItinerary,
  handleShowVideoConferenceModal,
  handleUnbookSlot,
  hasExpired,
  isGuest,
  isLoadingItinerary,
  selectedSessionId,
  showItineraryButton,
  status,
  parent
}) => {
  const [display, setDisplay] = useState('list')
  const isVideoConferenceEvent = invitation.meetingType.type === 'VC'
  const isPhoneEvent = invitation.meetingType.type === 'PHONE'

  if (!booking || booking.bookingSlots.length === 0) {
    return (
      <SimpleCard color='quaternary' className='sb-schedule' title='Your Bookings'>
        <EmptyMessage
          title='You currently have no bookings'
          image={<NoSchedule />}
          description='Try making a booking from the "Outstanding invitations" tab.'
        >
          <Button
            type='primary'
            className='cta'
            shape='round'
            size='large'
            onClick={() => handleChangeTab('outstanding')}
          >
            View Outstanding Invites
          </Button>
        </EmptyMessage>
      </SimpleCard>
    )
  }

  const orderedBookingSlotsBySession = _groupBy(
    _orderBy(booking.bookingSlots, bookingSlot => bookingSlot.slot.starts_at, ['asc']),
    'slot.session_id'
  )

  const { sessions } = event

  // const isChromeOnAppleDevice = isChromeOnApple()

  const isValidPhoneNumber = parent.mobile && parent.mobile.length > 6

  const phoneAlert = {
    type: isValidPhoneNumber ? 'success' : 'warning',
    message: isValidPhoneNumber
      ? `We will contact you on ${parent.mobile}. If this is not correct please update.`
      : `It appears we have no valid phone number. Please update so we can contact you.`
  }

  return (
    <div>
      <SimpleCard
        className='sb-schedule'
        color='quaternary'
        action={
          showItineraryButton ? (
            <Button
              type='primary'
              className='cta'
              size='large'
              shape='round'
              onClick={handleShowItinerary}
              loading={isLoadingItinerary}
            >
              <span className='sb-schedule__action--small'>Print</span>
              <span className='sb-schedule__action--large'>Print / Email Itinerary</span>
            </Button>
          ) : (
            ''
          )
        }
        badge={
          <Badge size='medium' type='success'>
            {booking.bookingSlots.length}
          </Badge>
        }
        title='Your Bookings'
        strapline='Select a session to filter and view your booked appointments below.'
      >
        {/**
        isVideoConferenceEvent && isChromeOnAppleDevice && !hasExpired && (
          <Alert
            type='warning'
            title='Browser Support'
            message='It appears you are using Chrome on an Apple device. Apple requires you to use Safari to use Video/Audio features, please remember to login to Events via Safari on the day of the event.'
          />
        )**/}

        {isPhoneEvent && !hasExpired && (
          <Alert type={phoneAlert.type} title='Verify Phone Number' message={phoneAlert.message}>
            <Button ghost size='large' shape='round'>
              <Link to='/account'>Update</Link>
            </Button>
          </Alert>
        )}

        <SessionControls
          currentDisplay={display}
          currentSession={selectedSessionId}
          handleSetDisplay={view => setDisplay(view)}
          handleSessionChange={sessionId => handleSelectedSessionChange(sessionId)}
          sessions={sessions}
        />

        {sessions
          .filter(session => selectedSessionId === 'all' || session.id === selectedSessionId)
          .map(session => {
            const noOfAppointments = orderedBookingSlotsBySession[session.id]
              ? orderedBookingSlotsBySession[session.id].length
              : 0

            if (noOfAppointments === 0 && selectedSessionId !== 'all') {
              return (
                <EmptyMessage
                  title='No Slots Booked'
                  image={<NoSchedule />}
                  description='You currently have no slots booked for this session.'
                >
                  <Button type='primary' size='large' shape='round' onClick={() => handleSelectedSessionChange('all')}>
                    Remove Session Filters
                  </Button>
                </EmptyMessage>
              )
            }

            if (noOfAppointments === 0 && selectedSessionId === 'all') return null

            return (
              <div className='sb-schedule__session' key={session.id}>
                <SessionCard session={session} noOfAppointments={noOfAppointments}>
                  <div className={`sb-schedule__slots ${display === 'grid' ? 'sb-schedule__slots--grid' : ''}`}>
                    {orderedBookingSlotsBySession[session.id].map(bookingSlot => {
                      const { lesson, slot, student } = bookingSlot
                      const { group } = slot
                      const { location } = group
                      const note = getGroupLessonNote(group.groupLessonNotes, lesson)
                      const isUnbookingSlot = status && status.code === 'UNBOOKING' && status.slot.id === slot.id

                      return (
                        <SlotCard
                          key={bookingSlot.id}
                          event={event}
                          group={group}
                          handleShowComments={() => handleShowComments(bookingSlot)}
                          handleUnbookSlot={slot => handleUnbookSlot(slot)}
                          handleShowVideoConferenceModal={(slot, group, lesson, student) =>
                            handleShowVideoConferenceModal(slot, group, lesson, student)
                          }
                          invitation={invitation}
                          isLoadingComments={bookingSlotCommentsLoading.includes(bookingSlot.id)}
                          isGuest={isGuest}
                          isUnbookingSlot={isUnbookingSlot}
                          lesson={lesson}
                          location={location}
                          note={note}
                          hasExpired={hasExpired}
                          slot={slot}
                          showVideoConference={isVideoConferenceEvent}
                          student={student}
                          vcParent={parent}
                        />
                      )
                    })}
                  </div>
                </SessionCard>
              </div>
            )
          })}
      </SimpleCard>
    </div>
  )
}

Schedule.propTypes = {
  booking: PropTypes.object,
  invitation: PropTypes.object.isRequired,
  bookingSlotCommentsLoading: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired,
  handleChangeTab: PropTypes.func,
  handleSelectedSessionChange: PropTypes.func.isRequired,
  handleShowComments: PropTypes.func,
  handleShowItinerary: PropTypes.func,
  handleShowVideoConferenceModal: PropTypes.func,
  handleUnbookSlot: PropTypes.func,
  hasExpired: PropTypes.bool,
  isGuest: PropTypes.bool,
  isLoadingItinerary: PropTypes.bool,
  showItineraryButton: PropTypes.bool,
  status: PropTypes.object,
  parent: PropTypes.object
}

Schedule.defaultProps = {
  handleChangeTab: () => {},
  handleShowComments: () => {},
  handleShowItinerary: () => {},
  handleUnbookSlot: () => {},
  hasExpired: false,
  isGuest: false,
  isLoadingItinerary: false,
  showItineraryButton: true
}

export default Schedule
