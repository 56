import React from 'react'
import { Badge } from '@components'
import { Switch, Typography } from 'antd'
import { getFormattedSlotTime } from '@helpers'
import PropTypes from 'prop-types'

const { Text } = Typography

const ConflictedSlot = ({ slot }) => (
  <Badge className='sb-booking-drawer__slot-conflicted' size='small' type='success'>
    {`${slot.group.title} - ${slot.lesson.name}`}
  </Badge>
)

const Slot = ({ conflictingSlots, handleBookSlot, slot }) => {
  const conflictedSlot = conflictingSlots[slot.id] || null
  const slotTime = getFormattedSlotTime(slot.starts_at, slot.ends_at)

  return (
    <div className='sb-booking-drawer__slot'>
      <div className='sb-booking-drawer__slot-time'>
        <Switch disabled={conflictedSlot} onChange={() => handleBookSlot(slot)} />

        <div className='sb-booking-drawer__slot-content'>
          <Text>{slotTime}</Text>
        </div>
      </div>

      {conflictedSlot && <ConflictedSlot slot={conflictedSlot} />}
    </div>
  )
}

Slot.propTypes = {
  conflictingSlots: PropTypes.object.isRequired,
  handleBookSlot: PropTypes.func.isRequired,
  slot: PropTypes.object.isRequired
}
export default Slot
