import React from 'react'
import { IconItem, SimpleCard } from '@components'
import { Typography } from 'antd'
import { getFormattedEventDateRange, getMeetingTypeIcon } from '@helpers'
import { CalendarOutlined, DeploymentUnitOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

import './EventDetails.less'

const EventDetails = ({ children, event, loading, meetingType }) => {
  return (
    <SimpleCard color='tertiary' loading={loading}>
      {!loading && (
        <React.Fragment>
          <div className='sb-event-details__header'>
            <Typography.Title level={4}>Event Details</Typography.Title>
          </div>

          <div className='sb-event-details__body'>
            <IconItem
              iconSize='large'
              icon={<CalendarOutlined />}
              text={getFormattedEventDateRange(event.start_date, event.end_date)}
            />

            <IconItem iconSize='large' icon={<DeploymentUnitOutlined />} text={`${event.sessions.length} Session(s)`} />

            {meetingType && (
              <div className='sb-event-details__meeting-type'>
                <IconItem
                  key={meetingType.id}
                  iconSize='large'
                  icon={getMeetingTypeIcon(meetingType.type)}
                  text={meetingType.name}
                />
              </div>
            )}

            {children}
          </div>
        </React.Fragment>
      )}
    </SimpleCard>
  )
}

EventDetails.propTypes = {
  children: PropTypes.node,
  event: PropTypes.object,
  loading: PropTypes.bool,
  meetingType: PropTypes.object
}

export default EventDetails
