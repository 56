import React from 'react'
import { Container, UserMenu } from '@components'
import { Button, Skeleton, Typography } from 'antd'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import './Hero.less'

const Hero = ({ image, loading, showNavigation, showUserMenu, subheader, title, description }) => (
  <section className='sb-hero'>
    <Container>
      {loading && <Skeleton paragraph={{ rows: 2 }} active />}

      {!loading && (
        <div className='sb-hero__wrapper'>
          <div className={`sb-hero__content ${showNavigation ? 'sb-hero__content--compact' : ''}`}>
            <div className='sb-hero__blurb'>
              <div className='sb-hero__image'>{image && image}</div>

              <div>
                <Typography.Title level={2} className='sb-hero__title'>
                  {title}
                </Typography.Title>

                <Typography.Title level={5} className='sb-hero__subheader'>
                  {subheader}
                </Typography.Title>

                <Typography.Paragraph className='sb-hero__paragraph'>{description}</Typography.Paragraph>
              </div>
            </div>
          </div>

          <div className='sb-hero__navigation'>
            {showNavigation && (
              <div className='sb-hero__buttons'>
                <Button type='primary' shape='round' size='large'>
                  <Link to={`/parentsmeeting`}>View Your Events</Link>
                </Button>
              </div>
            )}

            {showUserMenu && (
              <div className='sb-hero__actions'>
                <UserMenu />
              </div>
            )}
          </div>
        </div>
      )}
    </Container>
  </section>
)

Hero.propTypes = {
  description: PropTypes.string,
  image: PropTypes.node,
  loading: PropTypes.bool,
  showLogo: PropTypes.bool,
  showUserMenu: PropTypes.bool,
  showNavigation: PropTypes.bool,
  subheader: PropTypes.string,
  title: PropTypes.string
}

Hero.defaultProps = {
  description: '',
  image: null,
  loading: false,
  showLogo: false,
  showNavigation: true,
  showUserMenu: true,
  subheader: '',
  title: ''
}

export default Hero
