import React from 'react'
import dayjs from 'dayjs'
import { Container } from '@components'
import { Typography } from 'antd'
import { Link } from 'react-router-dom'
import { ReactComponent as Wave } from '../../../assets/wave.svg'
import schoolBookingLogo from '@assets/school-booking-logo.svg'
import { authService } from '@services'
import PropTypes from 'prop-types'
import './Footer.less'

const { Paragraph, Title } = Typography

const Footer = ({ currentEventId, isGuest }) => {
  const parentsMeetingUrl = currentEventId ? `/parentsmeeting/${currentEventId}` : '/parentsmeeting'

  return (
    <section className='sb-footer'>
      <Wave className='sb-footer__wave' />

      <Container className='sb-footer__container'>
        {!isGuest && (
          <div className='sb-footer__explore'>
            <Title level={5}>Explore</Title>
            <Link to={parentsMeetingUrl}>Your Events</Link>
            <Link to='/notifications'>Notifications</Link>
            <Link to='/account'>Account</Link>
            <Link to='#' onClick={() => authService.logout()}>
              Logout
            </Link>
          </div>
        )}

        {isGuest && (
          <div className='sb-footer__guest'>
            <Title level={5}>SchoolBooking Ltd</Title>
            <Paragraph className='sb-footer__guest-blurb'>
              This service is provided by SchoolBooking Ltd - the education booking system.
            </Paragraph>

            <div className='sb-footer__guest-links'>
              <a href='https://www.schoolbooking.com/parents' target='_blank' rel='noreferrer'>
                Parent Meetings
              </a>

              <a href='https://www.schoolbooking.com/bookings' target='_blank' rel='noreferrer'>
                Booking Systems
              </a>

              <a href='https://www.schoolbooking.com/lettings' target='_blank' rel='noreferrer'>
                Lettings Management
              </a>
            </div>
          </div>
        )}

        <div className='sb-footer__powered-by'>
          <Title level={5}>Powered By</Title>

          <img src={schoolBookingLogo} alt='School Booking logo' />

          <div className='sb-footer__copyright'>
            <Paragraph>SchoolBooking Ltd</Paragraph>
            <Paragraph>Copyright &copy;{dayjs().format('YYYY')}</Paragraph>
          </div>
        </div>

        <div className='sb-footer__build'>
          <Title level={5}>Version</Title>
          <Paragraph>{process.env.REACT_APP_SB_BUILD_NO || 'n/a'}</Paragraph>
        </div>
      </Container>
    </section>
  )
}

Footer.propTypes = {
  currentEvent: PropTypes.number,
  isGuest: PropTypes.bool.isRequired
}

Footer.defaultProps = {
  currentEvent: null
}

export default Footer
