import React from 'react'
import PropTypes from 'prop-types'
import './IconItem.less'

const IconItem = ({ className, icon, iconSize, text }) => (
  <div className={`sb-icon-item${className ? ' ' + className : ''}`}>
    <div className={`icon ${iconSize}`}>{icon}</div>
    <div className='text'>{text}</div>
  </div>
)

IconItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.object.isRequired,
  iconSize: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
}

IconItem.defaultProps = {
  className: null,
  iconSize: 'medium'
}

export default IconItem
