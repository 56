import { isDateTimeRangeOverlapping } from '@helpers'

/**
 *
 * Returns all slots that can't be booked by the current user
 * as the slot time would clash with an existing booking
 * from another group
 *
 * @param {Object} availableSlots
 * @param {Object} booking
 * @returns Object
 *
 */
export const getConflictingSlots = (availableSlots, booking) => {
  const conflictingSlots = {}

  if (!booking || !booking.bookingSlots || !availableSlots) return conflictingSlots

  const { bookingSlots } = booking

  bookingSlots.forEach(bookingSlot => {
    availableSlots.forEach(availableSlot => {
      const { slot } = bookingSlot

      const isDateOverlapping = isDateTimeRangeOverlapping(
        availableSlot.starts_at,
        availableSlot.ends_at,
        slot.starts_at,
        slot.ends_at
      )

      if (isDateOverlapping) {
        conflictingSlots[availableSlot.id] = {
          ...bookingSlot.slot,
          lesson: bookingSlot.lesson
        }
      }
    })
  })

  return conflictingSlots
}
