import React from 'react'
import { Skeleton, Typography } from 'antd'
import PropTypes from 'prop-types'

import './Card.less'

const { Title } = Typography

const Card = ({ children, headerActions, loading, title, type }) => (
  <div className={`sb-card sb-card--${type} ${loading ? 'sb-card--loading' : ''}`}>
    <div className='sb-card__heading'>
      <Title level={4} className='sb-card__title'>
        {loading && !title ? <Skeleton paragraph={{ rows: 1, width: '100%' }} active title={false} /> : title}
      </Title>

      {headerActions && !loading && <div className='sb-card__heading-actions'>{headerActions}</div>}
    </div>

    <div className='sb-card__content'>
      {loading ? <Skeleton paragraph={{ rows: 3 }} title={false} active /> : children}
    </div>
  </div>
)

export default Card

Card.propTypes = {
  headerActions: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(['danger', 'info', 'warning'])
}

Card.defaultProps = {
  children: null,
  headerActions: null,
  loading: false,
  title: null,
  type: 'info'
}
