import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { toDayJs } from '@helpers'

dayjs.extend(isBetween)

const isSessionLive = session => {
  const today = toDayJs()
  const starts = toDayJs(session.starts_at)
  const ends = toDayJs(session.ends_at)

  return today.isBetween(starts, ends)
}

export default isSessionLive
