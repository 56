import { gql } from '@apollo/client'

export const RECOVERY_SEND_EMAIL_MUTATION = gql`
  mutation($input: SendRecoveryEmailInput!) {
    sendRecoveryEmail(input: $input)
  }
`

export const RECOVERY_RESET_ACCOUNT_MUTATION = gql`
  mutation($input: UpdatePasswordFromActivationCodeInput!) {
    updatePasswordFromActivationCode(input: $input)
  }
`
