import { toDayJs } from '@helpers'

const getFormattedEventDateRange = (starts, ends, showTime = true) => {
  const startDate = toDayJs(starts)
  const endDate = toDayJs(ends)

  if (startDate.isSame(ends, 'day')) {
    if (showTime) {
      return `${startDate.format('ddd D MMM YYYY')} ${startDate.format('H:mm')} - ${endDate.format('H:mm')}`
    } else {
      return `${startDate.format('ddd D MMM YYYY')}`
    }
  }

  return `${startDate.format('ddd D MMM')} - ${endDate.format('ddd D MMM YYYY')}`
}

export default getFormattedEventDateRange
