import React, { useEffect, useState } from 'react'
import { Button, Typography } from 'antd'
import { Badge, CustomModal, EmptyMessage, Loader, SimpleCard } from '@components'
import { JitsiMeeting } from '@jitsi/react-sdk'
import { getLessonSubject, getRoomName, getSanitizedName, getStudentFullname, toDayJs } from '@helpers'
import { ReactComponent as VideoConference } from '@assets/video-conference.svg'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import duration from 'dayjs/plugin/duration'
import isToday from 'dayjs/plugin/isToday'
import objectSupport from 'dayjs/plugin/objectSupport'
import PropTypes from 'prop-types'

import './VideoConferenceModal.less'

dayjs.extend(advancedFormat)
dayjs.extend(duration)
dayjs.extend(isToday)
dayjs.extend(objectSupport)

const secondsInDay = 60 * 60 * 24

const timerStatusTypes = {
  future: 'info',
  current: 'success',
  expired: 'danger',
  countdown: 'warning'
}

const VideoConferenceModal = ({ event, group, handleCancel, lesson, slot, student, username }) => {
  const [loading, setLoading] = useState(true)
  const [jitsiApi, setJitsiApi] = useState(null)
  const [timerDetails, setTimerDetails] = useState({ isReady: false })
  const { vc_auto_disconnect: autoDisconnect } = event
  const startTime = toDayJs(slot.starts_at)
  const endTime = toDayJs(slot.ends_at)
  const hasExpired = dayjs().isAfter(endTime)

  useEffect(() => {
    updateTimer()

    const timer = setInterval(() => {
      updateTimer()
    }, 1000)

    return () => {
      clearInterval(timer)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getTimerDetails = () => {
    const now = dayjs()
    const secondsToStart = Math.floor(now.diff(startTime) / 1000)
    const secondsToEnd = Math.floor(now.diff(endTime) / 1000)
    const status = getStatus(secondsToStart, secondsToEnd)
    let duration = null
    let caption = null

    if (status.type === 'future') {
      duration = getDuration(secondsToStart)
      caption = duration.days > 0 ? null : 'Due to start in:'
    }

    if (status.type === 'expired') {
      duration = getDuration(secondsToEnd)

      if (duration.days === 0) {
        caption = autoDisconnect ? 'Expired' : 'Over running by:'
      }
    }

    if (status.type === 'current') {
      duration = getDuration(secondsToEnd)
      caption = autoDisconnect ? 'Call will terminate in:' : 'Time remaining:'
    }

    return {
      isReady: true,
      status,
      caption,
      duration,
      isCountingdownFinal: status.countdownFinal,
      type: status.countdown ? 'warning' : timerStatusTypes[status.type]
    }
  }

  const getStatus = (secondsToStart, secondsToEnd) => {
    return {
      countdownFinal: (secondsToEnd < 0 && secondsToEnd >= -10) || (secondsToStart < 0 && secondsToStart >= -10),
      countdown: (secondsToEnd < 0 && secondsToEnd >= -30) || (secondsToStart < 0 && secondsToStart >= -30),
      type: secondsToStart < 0 ? 'future' : secondsToEnd >= 0 ? 'expired' : 'current'
    }
  }

  const getDuration = durationInSeconds => {
    const secondsRemaining = Math.abs(durationInSeconds) % secondsInDay

    return {
      days: Math.floor(Math.abs(durationInSeconds) / secondsInDay),
      time: dayjs({ seconds: secondsRemaining }).format('HH:mm:ss')
    }
  }

  const updateTimer = () => {
    setTimerDetails(getTimerDetails())
  }

  const handleJitsiReady = api => {
    setJitsiApi(api)

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const handleCloseModal = () => {
    if (jitsiApi) {
      jitsiApi.dispose()
    }

    handleCancel()
  }

  const hangup = () => {
    jitsiApi.executeCommand('hangup')
    handleCloseModal()
  }

  const {
    duration: timerDuration,
    caption: timerCaption,
    type: timerType,
    isReady: isTimerReady,
    isCountingdownFinal
  } = timerDetails

  const expiredAutoDisconnect = hasExpired && autoDisconnect
  const isToday = startTime.isToday()

  if (expiredAutoDisconnect && jitsiApi) {
    hangup()
  }

  return (
    <CustomModal
      className={`sb-video-conference-modal sb-video-conference-modal__${timerType}`}
      closable
      centered
      visible={true}
      width='100%'
      onCancel={handleCloseModal}
      maskClosable={false}
      footer={null}
      title={
        <div>
          <Typography.Title level={3} color='white'>
            {getStudentFullname(student)}
          </Typography.Title>

          <Typography.Paragraph color='white'>{group.title}</Typography.Paragraph>

          <Typography.Paragraph color='white'>{getLessonSubject(lesson)}</Typography.Paragraph>

          {!isToday && (
            <div className='sb-video-conference-modal__date'>
              <Typography.Paragraph color='white'>{startTime.format('dddd Do MMMM YYYY')}</Typography.Paragraph>

              <Typography.Text color='white' strong>
                {startTime.format('H:mma')} - {endTime.format('H:mma')}
              </Typography.Text>
            </div>
          )}

          {isToday && (
            <Badge
              className={`sb-video-conference-modal__time ${
                isCountingdownFinal ? ' sb-video-conference-modal__time--countdown' : ''
              }`}
              type={timerType}
            >
              <Typography.Text strong>
                {startTime.format('H:mma')} - {endTime.format('H:mma')}
              </Typography.Text>

              {isTimerReady && (
                <div>
                  {timerCaption}{' '}
                  {!expiredAutoDisconnect && <Typography.Text strong>{timerDuration.time}</Typography.Text>}
                </div>
              )}
            </Badge>
          )}
        </div>
      }
    >
      {!expiredAutoDisconnect && loading && <Loader message='Connecting to video conference....' />}

      {!expiredAutoDisconnect && (
        <div style={{ height: '100%', display: loading ? 'none' : 'block' }}>
          <JitsiMeeting
            domain='meet.schoolbooking.com'
            roomName={getRoomName(slot.id)}
            userInfo={{
              displayName: getSanitizedName(username)
            }}
            interfaceConfigOverwrite={{
              TOOLBAR_BUTTONS: [
                'microphone',
                'camera',
                'closedcaptions',
                'desktop',
                'fullscreen',
                'fodeviceselection',
                'hangup',
                'profile',
                'chat',
                'livestreaming',
                'etherpad',
                'settings',
                'raisehand',
                'videoquality',
                'filmstrip',
                'feedback',
                'stats',
                'shortcuts',
                'tileview',
                'download',
                'help',
                'mute-everyone'
              ]
            }}
            configOverwrite={{
              disableDeepLinking: true,
              hideConferenceSubject: true
            }}
            getIFrameRef={iframeRef => {
              iframeRef.style.height = '100%'
            }}
            onReadyToClose={() => handleCloseModal()}
            onApiReady={api => handleJitsiReady(api)}
          />
        </div>
      )}

      {expiredAutoDisconnect && (
        <SimpleCard color='quaternary'>
          <EmptyMessage title='Appointment Expired' image={<VideoConference />}>
            <div className='sb-video-conference-modal__expired-text'>
              <Typography.Paragraph color='white'>This appointment has expired.</Typography.Paragraph>
              <Typography.Paragraph color='white'>
                This call was set to auto disconnect after its alloted time.
              </Typography.Paragraph>
            </div>

            <Button type='primary' className='cta' shape='round' size='large' onClick={() => handleCloseModal()}>
              Back to Bookings
            </Button>
          </EmptyMessage>
        </SimpleCard>
      )}
    </CustomModal>
  )
}

VideoConferenceModal.propTypes = {
  handleCancel: PropTypes.func,
  slot: PropTypes.object,
  username: PropTypes.string
}

export default VideoConferenceModal
