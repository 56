const isInvitationGroupBooked = (invitationGroup, booking) => {
  if (!booking) return false

  return booking.bookingSlots.some(
    bookingSlot =>
      bookingSlot.slot.group.id === invitationGroup.group.id &&
      bookingSlot.lesson.id === invitationGroup.lesson.id &&
      bookingSlot.student.id === invitationGroup.student.id
  )
}

export default isInvitationGroupBooked
