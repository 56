import React from 'react'
import { Typography } from 'antd'
import PropTypes from 'prop-types'

const { Text } = Typography

const ErrorMessage = ({ message }) => (
  <div className='error'>
    <Text type='danger' strong>
      {message}
    </Text>
  </div>
)

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired
}

export default ErrorMessage
