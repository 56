import React from 'react'
import { CheckCircleFilled } from '@ant-design/icons'
import PropTypes from 'prop-types'

import './ImageRadioGroup.less'
import { Typography } from 'antd'

const ImageRadioGroup = ({ handleSelect, options }) => (
  <div className='sb-image-radio-group'>
    {options.map(option => {
      return (
        <div
          key={option.value}
          className={`sb-image-radio ${option.selected ? 'sb-image-radio--selected' : 'sb-image-radio--default'}`}
          onClick={() => handleSelect(option)}
        >
          {option.selected && <CheckCircleFilled className='sb-image-radio__selected' />}

          <div className='sb-image-radio__image'>{option.image}</div>

          <Typography.Title level={4} className='sb-image-radio__title'>
            {option.label}
          </Typography.Title>

          <Typography.Text className='sb-image-radio__description'>{option.description}</Typography.Text>
        </div>
      )
    })}
  </div>
)

ImageRadioGroup.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
}

export default ImageRadioGroup
