const getConfig = () => {
  try {
    const http = new XMLHttpRequest()
    http.open('GET', '/meta.json', false)
    http.setRequestHeader('Cache-Control', 'no-cache, no-store, max-age=0')
    http.setRequestHeader('Pragma', 'no-cache') // Maybe required for Chrome
    http.send(null)
    return JSON.parse(http.responseText)
  } catch (err) {
    console.log('meta.json not found or user offline')
    return null
  }
}

export default getConfig
