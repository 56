import React from 'react'
import PropTypes from 'prop-types'
import './Container.less'

const Container = ({ children, className }) => (
  <div className={`sb-container ${className}`}>{children}</div>
)

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

Container.defaultProps = {
  className: ''
}

export default Container
