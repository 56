import React, { createContext, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { AUTH_USER_QUERY, NOTIFICATION_UPDATE_READ_MUTATION } from '@graphql'
import { showNotificationPopup, notificationAudio } from '@helpers'
import { notification, Typography } from 'antd'
import { produce } from 'immer'

const { Title } = Typography

const UserContext = createContext({})

const UserProvider = ({ children }) => {
  const [popupOnNotification, setPopupOnNotification] = useState(true)
  const [user, setUser] = useState({ loading: true })

  useEffect(() => {
    if (popupOnNotification) {
      notify()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  /**
   * Poll for notifications & display popup
   */
  useQuery(AUTH_USER_QUERY, {
    fetchPolicy: 'network-only',
    pollInterval: process.env.REACT_APP_NOTIFICATION_POLL_INTERVAL,
    notifyOnNetworkStatusChange: true,

    onError: error => {
      setUser({ loading: false, error })
    },

    onCompleted: data => {
      setUser({ ...data.me, loading: false, error: false })
    }
  })

  const [mutateMarkAsRead] = useMutation(NOTIFICATION_UPDATE_READ_MUTATION)

  const getNotificationTitle = myNotification => {
    return (
      <div>
        <Title level={5}>{myNotification.event.title}</Title>
        <Title level={4}>{myNotification.notification.title}</Title>
      </div>
    )
  }

  const getEventNotificationCounts = id => {
    if (!user || user.loading || user.error) return { unread: 0, total: 0 }

    return user.myNotifications.reduce(
      (summary, notification) => {
        if (notification.event.id === id) {
          if (!notification.read) summary.unread++
          summary.total++
        }

        return summary
      },
      { unread: 0, total: 0 }
    )
  }

  const notify = () => {
    const notificationIds = []

    if (user && !user.loading && user.myNotifications) {
      user.myNotifications.forEach(myNotification => {
        if (showNotificationPopup(myNotification)) {
          notificationAudio.play()

          const type = myNotification.notification.status.code === 'CRITICAL' ? 'warning' : 'info'

          notification[type]({
            key: myNotification.id,
            message: getNotificationTitle(myNotification),
            description: myNotification.notification.description,
            className: `event-notification event-notification-${type}`,
            duration: 0,
            maxCount: 4,
            onClose: () => markAsRead(myNotification)
          })

          notificationIds.push(myNotification.id)
        }
      })
    }
  }

  const markAsRead = notification => {
    return mutateMarkAsRead({
      variables: { input: { id: notification.id, read: true } },

      update: (client, result) => {
        const index = user.myNotifications.findIndex(myNotification => myNotification.id === notification.id)

        const unreadCount = result.data.updateNotificationRecipient

        setUser(
          produce(user, draft => {
            draft.myNotifications[index].read = true
            draft.unreadNotificationCount = unreadCount
          })
        )
      }
    })
  }

  return (
    <UserContext.Provider
      value={{
        user,
        markAsRead,
        getEventNotificationCounts,
        setPopupOnNotification
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export { UserProvider, UserContext }
