import React from 'react'
import { SimpleCard } from '@components'
import { Button, Typography } from 'antd'
import { ReactComponent as People } from '@assets/people.svg'
import PropTypes from 'prop-types'

import './NoEventsPlaceholder.less'

const { Paragraph, Title } = Typography

const NoEventsPlaceholder = ({ title, message, showHomeButton }) => {
  return (
    <SimpleCard color='secondary' className='sb-no-events-placeholder'>
      <People className='sb-no-events-placeholder__image' />

      <Title className='sb-no-events-placeholder__title' level={2}>
        {title}
      </Title>

      <Paragraph className='sb-no-events-placeholder__text'>{message}</Paragraph>

      {showHomeButton && (
        <Button
          className='sb-no-events-placeholder__home'
          href='/parentsmeeting'
          size='large'
          shape='round'
          type='primary'
        >
          View More Events
        </Button>
      )}
    </SimpleCard>
  )
}

NoEventsPlaceholder.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  showHomeButton: PropTypes.bool.isRequired
}

export default NoEventsPlaceholder
