import React from 'react'
import { authService } from '@services'
import { Redirect, Route } from 'react-router-dom'
import { UserProvider } from '@context'

function ProtectedRoute ({ component: Component, ...restOfProps }) {
  const redirectPath = window.location.pathname
  const isAuthenticated = authService.isAuthenticated()

  return (
    <Route
      {...restOfProps}
      render={props =>
        isAuthenticated ? (
          <UserProvider>
            <Component {...props} />
          </UserProvider>
        ) : (
          <Redirect to={`/login?redirectTo=${redirectPath}`} />
        )
      }
    />
  )
}

export default ProtectedRoute
