import { gql } from '@apollo/client'
import { GUEST_INVITATION_FIELDS_FRAGMENT } from '../fragments'

/**
 * DELETE INVITATION
 */
export const GUEST_INVITATIONS_DELETE_MUTATION = gql`
  mutation($id: ID!) {
    deleteGuestInvitation(id: $id) {
      id
    }
  }
`

/**
 * RESEND INVITATION
 */
export const GUEST_INVITATIONS_RESEND_INVITE_MUTATION = gql`
  mutation($id: ID!) {
    resendGuestInvitation(id: $id) {
      success
    }
  }
`

/**
 * CREATE INVITATION
 */
export const GUEST_INVITATIONS_CREATE_INVITE_MUTATION = gql`
  ${GUEST_INVITATION_FIELDS_FRAGMENT}

  mutation($input: CreateGuestInvitationInput!) {
    createGuestInvitation(input: $input) {
      ...GuestInvitationFields
    }
  }
`
