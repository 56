import { gql } from '@apollo/client'

import { NOTIFICATION_FIELDS_FRAGMENT } from '../fragments'

const EVENT_NOTIFICATIONS_QUERY = gql`
  ${NOTIFICATION_FIELDS_FRAGMENT}

  query ($whereNotifications: QueryNotificationsWhereWhereConditions) {
    notifications(where: $whereNotifications) {
      ...NotificationFields
    }
  }
`

const NOTIFICATIONS_QUERY = gql`
  ${NOTIFICATION_FIELDS_FRAGMENT}

  query {
    me {
      id
      unreadNotificationCount
      myNotifications {
        ...NotificationFields
      }
    }
  }
`

export { EVENT_NOTIFICATIONS_QUERY, NOTIFICATIONS_QUERY }
