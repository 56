import { Badge } from 'antd'

import { IdcardOutlined, LogoutOutlined, NotificationOutlined } from '@ant-design/icons'

import { authService } from '@services'

export const getMenuItems = unreadNotificationCounts => [
  {
    key: 'AccountDetails',
    caption: 'Account Settings',
    href: '/account',
    handleClick: () => {},
    icon: <IdcardOutlined style={{ fontSize: 20 }} />,
    divider: false
  },
  {
    key: 'Notifications',
    caption: (
      <span>
        Notifications <Badge count={unreadNotificationCounts} />
      </span>
    ),
    href: '/notifications',
    handleClick: () => {},
    icon: <NotificationOutlined style={{ fontSize: 20 }} />,
    divider: false
  },
  {
    key: 'LogOut',
    caption: 'Log Out',
    href: '#',
    handleClick: () => authService.logout(),
    icon: <LogoutOutlined style={{ fontSize: 20 }} />,
    divider: true
  }
]
