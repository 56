import { gql } from '@apollo/client'

export const EVENT_VIEWS_MUTATION = gql`
  mutation ($input: CreateEventViewInput!) {
    createEventView(input: $input) {
      id
      event_id
      user_id
      last_viewed_at
    }
  }
`
