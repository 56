import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { toDayJs } from '@helpers'

dayjs.extend(isBetween)

const isEventLive = event => {
  const today = dayjs()
  const starts = toDayJs(event.start_date)
  const ends = toDayJs(event.end_date)

  return today.isBetween(starts, ends, 'day', '[]')
}

export default isEventLive
