import React from 'react'
import { Typography } from 'antd'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import parentsLogo from '@assets/parents-logo.svg'

import './Sidebar.less'

const { Title } = Typography

const Sidebar = ({ children, mode, title }) => (
  <div className={classnames({ sidebar: true, [`${mode}-background`]: mode })}>
    <img src={parentsLogo} alt='Parents logo' className='logo' />

    <div className='header'>
      <Title level={2} className='title'>
        SchoolBooking
      </Title>

      <Title level={3} className='subtitle'>
        {title}
      </Title>
    </div>

    {children}
  </div>
)

Sidebar.props = {
  mode: PropTypes.string,
  title: PropTypes.string.isRequired
}

Sidebar.defaultProps = {
  mode: ''
}

export default Sidebar
