import React from 'react'
import { Badge } from '@components'
import { Typography } from 'antd'
import { DeploymentUnitOutlined } from '@ant-design/icons'
import { getFormattedSessionDateRange } from '@helpers'
import PropTypes from 'prop-types'

import './SessionCard.less'

const { Title } = Typography

const SessionCard = ({ children, session, noOfAppointments }) => {
  const sessionDateRange = getFormattedSessionDateRange(session.starts_at, session.ends_at)

  return (
    <div className='sb-session-card'>
      <div className='sb-session-card__title'>
        <Title level={5}>
          <DeploymentUnitOutlined /> {session.title} - {sessionDateRange}{' '}
        </Title>

        <Badge className='sb-session-card__no-appointments' type='info'>
          {noOfAppointments} Appointments
        </Badge>
      </div>

      <div className='sb-session-card__content'>{children}</div>
    </div>
  )
}

SessionCard.propTypes = {
  children: PropTypes.node.isRequired,
  noOfAppointments: PropTypes.number.isRequired,
  session: PropTypes.object.isRequired
}

export default SessionCard
