import React from 'react'
import { Button, Form, Input } from 'antd'
import PropTypes from 'prop-types'

const PasswordResetForm = ({
  buttonProps,
  confirmPasswordLabel,
  handleSubmit,
  loading,
  formRef,
  passwordLabel,
  showActivationCode,
  ...props
}) => {
  const { caption: buttonCaption, ...buttonAttributes } = buttonProps

  return (
    <Form
      name='reset-password'
      className='sb-reset-password'
      form={formRef}
      onFinish={formData => handleSubmit(formData)}
      {...props}
    >
      {showActivationCode && (
        <Form.Item
          name='activation_code'
          label='Activation Code'
          tooltip='Add code from your email.'
          style={{ marginBottom: 10, display: 'none' }}
          rules={[
            {
              required: true,
              message: 'Please input your activation code!'
            }
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>
      )}

      <Form.Item
        name='password'
        label={passwordLabel}
        tooltip='Password must be between 8-20 characters.'
        style={{ marginBottom: 10 }}
        rules={[
          {
            required: true,
            message: 'Please input your password!'
          },
          {
            min: 8,
            message: 'Password must have a minimum of 8 characters.'
          },
          {
            max: 20,
            message: 'Password must have a maximum of 20 characters.'
          }
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name='password_confirmation'
        label={confirmPasswordLabel}
        dependencies={['password']}
        tooltip='Password must be between 8-20 characters.'
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!'
          },
          ({ getFieldValue }) => ({
            validator (_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('The passwords must match!'))
            }
          })
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Button
        className='sb-reset-password__save-button'
        type='primary'
        shape='round'
        htmlType='submit'
        loading={loading}
        {...buttonAttributes}
      >
        {buttonCaption}
      </Button>
    </Form>
  )
}

PasswordResetForm.props = {
  buttonProps: PropTypes.object.isRequired,
  confirmPasswordLabel: PropTypes.string,
  formRef: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  passwordLabel: PropTypes.string,
  showActivationCode: PropTypes.showActivationCode
}

PasswordResetForm.defaultProps = {
  confirmPasswordLabel: 'Confirm Password',
  passwordLabel: 'Password',
  showActivationCode: false
}

export default PasswordResetForm
