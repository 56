import React from 'react'
import { Drawer, List, Result, Space, Typography } from 'antd'
import { BookOutlined, ExclamationCircleOutlined, UserOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import Progress from './Progess'
import Slot from './Slot'
import { getLessonSubject, getStudentFullname } from '@helpers'
import { getConflictingSlots } from './data.source.js'

import './BookingDrawer.less'

const { Text, Title } = Typography

const BookingDrawer = ({
  availableGroupSlots,
  booking,
  handleBookSlot,
  handleClose,
  invitationGroup,
  isVisible,
  progress
}) => {
  const { lesson, group, student } = invitationGroup
  const lessonSubject = getLessonSubject(lesson)
  const studentName = getStudentFullname(student)
  const conflictingSlots = getConflictingSlots(availableGroupSlots, booking)

  return (
    <Drawer
      className='sb-booking-drawer'
      title={
        <div>
          <Title level={3}>{group.title}</Title>
          <Space direction='vertical'>
            <Space>
              <BookOutlined />
              <Text>{lessonSubject}</Text>
            </Space>
            <Space>
              <UserOutlined />
              <Text>{studentName}</Text>
            </Space>
          </Space>
        </div>
      }
      onClose={handleClose}
      visible={isVisible}
    >
      {progress && <Progress progress={progress} />}

      {!progress && availableGroupSlots.length === 0 && (
        <Result
          key='success'
          title='All available slots have been taken!'
          subTitle='Additional slots may appear if the event administrator adds slots or cancellations occur.'
          icon={<ExclamationCircleOutlined />}
        />
      )}

      {!progress && availableGroupSlots.length > 0 && (
        <List
          dataSource={availableGroupSlots}
          renderItem={slot => (
            <List.Item key={slot.id}>
              <Slot slot={slot} conflictingSlots={conflictingSlots} handleBookSlot={slot => handleBookSlot(slot)} />
            </List.Item>
          )}
        />
      )}
    </Drawer>
  )
}

BookingDrawer.propTypes = {
  availableGroupSlots: PropTypes.array.isRequired,
  booking: PropTypes.object,
  invitationGroup: PropTypes.object.isRequired,
  handleBookSlot: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired
}

BookingDrawer.defaultProps = {
  booking: {}
}

export default BookingDrawer
