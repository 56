import { BankOutlined, PhoneOutlined, VideoCameraOutlined } from '@ant-design/icons'

export const meetingTypes = {
  PHONE: {
    key: 'phone',
    icon: <PhoneOutlined />,
    popover: 'This event has the option to engage by phone.'
  },

  VC: {
    key: 'video',
    icon: <VideoCameraOutlined />,
    popover: 'This event has the option to engage by video conference.'
  },

  VENUE: {
    key: 'venue',
    icon: <BankOutlined />,
    popover: 'This event has the option to engage in person.'
  }
}
