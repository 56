import dayjs from 'dayjs'
import fromNow from 'dayjs/plugin/relativeTime'
import { toDayJs } from '@helpers'

dayjs.extend(fromNow)

const getHumanTimeDuration = date => {
  return toDayJs(date).fromNow(date)
}

export default getHumanTimeDuration
