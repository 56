import React from 'react'
import { Button, List, Skeleton, Typography } from 'antd'
import { Badge, SimpleCard } from '@components'
import { getDurationToEvent, isEventLive, isEventUpcoming } from '@helpers'
import PropTypes from 'prop-types'

import './EventsList.less'

const EventsList = ({
  activeEvent,
  className,
  emptyMessage,
  events,
  title,
  handleEventChange,
  handleShowMoreEvents,
  loading,
  perPage
}) => {
  const paginatedEvents = [...events].splice(0, perPage)
  const totalNoOfEvents = events.length

  return (
    <SimpleCard color='secondary' className={`${className} sb-event-list`}>
      <div className='sb-event-list__header'>
        <Typography.Title level={5}>{title}</Typography.Title>
      </div>

      {loading && (
        <Skeleton title={false} paragraph={{ rows: 5, width: ['100%', '100%', '100%', '100%', '100%'] }} active />
      )}

      {!loading && (
        <React.Fragment>
          <List
            className='sb-event-list__list'
            dataSource={paginatedEvents}
            size='small'
            locale={{ emptyText: emptyMessage }}
            renderItem={event => {
              const durationTillEvent = getDurationToEvent(event.start_date, event.end_date)
              const badgeType = isEventLive(event) ? 'success' : isEventUpcoming(event) ? 'warning' : 'info-dark'
              const isActiveEvent = activeEvent && activeEvent.id === event.id

              return (
                <List.Item className={`sb-event-list__item ${isActiveEvent ? 'sb-event-list__item--active' : ''}`}>
                  <div className='sb-event-list__title'>
                    <Button type='link' onClick={() => handleEventChange(event.id)}>
                      {event.title}
                    </Button>
                  </div>

                  <Badge className='sb-event-list__badge' size='small' type={badgeType}>
                    {durationTillEvent}
                  </Badge>
                </List.Item>
              )
            }}
          />

          {perPage < totalNoOfEvents && (
            <div className='sb-event-list__more'>
              <Button type='link' size='small' onClick={() => handleShowMoreEvents()}>
                Show More...
              </Button>
            </div>
          )}
        </React.Fragment>
      )}
    </SimpleCard>
  )
}

EventsList.props = {
  activeEvent: PropTypes.object,
  className: PropTypes.string,
  emptyMessage: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
  handleEventChange: PropTypes.func.isRequired,
  perPage: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  handleShowMoreEvents: PropTypes.func.isRequired
}

EventsList.defaultProps = {
  activeEvent: null,
  className: '',
  loading: false
}

export default EventsList
