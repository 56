import { hasEventExpired } from '@helpers'

const showNotificationPopup = myNotification => {
  const eventExpired = hasEventExpired(myNotification.event.end_date, 24)

  return (
    !myNotification.read &&
    !eventExpired &&
    myNotification.notification.active &&
    myNotification.notification.recipientType.code === 'PARENTS' &&
    myNotification.notification.type.code === 'APP'
  )
}

export default showNotificationPopup
