import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './Burger.less'

const Burger = ({ handleToggle, isOpen }) => (
  <div className={classnames({ open: isOpen }, 'sb-burger')} onClick={() => handleToggle()}>
    <em />
    <em />
    <em />
  </div>
)

Burger.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool
}

Burger.defaultProps = {
  isOpen: false
}

export default Burger
