import dayjs from 'dayjs'
import { toDayJs } from '@helpers'

const isEventUpcoming = event => {
  const today = dayjs()
  const starts = toDayJs(event.start_date)

  return today.isBefore(starts, 'day')
}

export default isEventUpcoming
