import _get from 'lodash/get'

const getValidationErrors = apolloError => {
  const { graphQLErrors } = apolloError
  const errors = []

  if (
    graphQLErrors.length > 0 &&
    _get(graphQLErrors[0], 'extensions.category') === 'validation'
  ) {
    const validationErrors = graphQLErrors[0].extensions.validation
    const errorKeys = Object.keys(validationErrors)

    errorKeys.forEach(key => {
      errors.push(validationErrors[key])
    })
  }

  return errors
}

export default getValidationErrors
