import dayjs from 'dayjs'
import { toDayJs } from '@helpers'

const isEventPast = event => {
  const today = dayjs()
  const ends = toDayJs(event.end_date)

  return today.isAfter(ends, 'day')
}

export default isEventPast
