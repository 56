import React from 'react'
import { Container } from '@components'
import PropTypes from 'prop-types'

const MainContent = ({ children, className }) => <Container className={`${className}`}>{children}</Container>

MainContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

MainContent.defaultProps = {
  className: ''
}

export default MainContent
