import './App.less'
import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Loader, SystemMessage, ProtectedRoute } from '@components'

const Account = React.lazy(() => import('./pages/Account'))
const ActivateAccount = React.lazy(() => import('./pages/ActivateAccount'))
const Attendance = React.lazy(() => import('./pages/Attendance'))
const Impersonate = React.lazy(() => import('./pages/Impersonate'))
const ParentsMeetingBooking = React.lazy(() => import('./pages/ParentsMeeting/Bookings/ParentsMeetingBooking'))
const ParentsMeetingGuestBooking = React.lazy(() =>
  import('./pages/ParentsMeeting/Bookings/ParentsMeetingGuestBooking')
)

const Login = React.lazy(() => import('./pages/Login'))
const Notifications = React.lazy(() => import('./pages/Notifications'))
const SessionExpired = React.lazy(() => import('./pages/Errors/SessionExpired'))
const PageNotFound = React.lazy(() => import('./pages/Errors/PageNotFound'))

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <ProtectedRoute path='/' exact component={ParentsMeetingBooking} />

        <ProtectedRoute path='/account' exact component={Account} />

        <Route path='/activate-account' component={ActivateAccount} />
        <Route path='/impersonate' component={Impersonate} />
        <Route path='/parentsmeeting/:id/attendance/:token' component={Attendance} />
        <Route path='/parentsmeeting/guest/:token' component={ParentsMeetingGuestBooking} />

        <ProtectedRoute path='/parentsmeeting/:id?' component={ParentsMeetingBooking} />

        <ProtectedRoute path='/notifications' exact component={Notifications} />

        <Route path='/recovery' component={Login} />

        <Route path='/login' component={Login} />

        <Route path='/session-expired' component={SessionExpired} />

        <Route path='*' component={PageNotFound} />
      </Switch>
      <SystemMessage />
    </Suspense>
  )
}

export default App
