import React from 'react'
import { Card, Skeleton } from 'antd'
import Header from './Header'
import PropTypes from 'prop-types'

import './EventCard.less'

const EventCard = ({
  actions,
  children,
  className,
  header,
  loading,
  color
}) => (
  <Card
    className={`event-card ${className} ${color}`}
    actions={actions}
    title={
      <Header title={header.title} extra={header.extra} loading={loading} />
    }
  >
    <div className='content'>
      {loading ? <Skeleton loading={true} active /> : children}
    </div>
  </Card>
)

EventCard.propTypes = {
  actions: PropTypes.array,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  header: PropTypes.object.isRequired,
  color: PropTypes.string
}

EventCard.defaultProps = {
  actions: null,
  className: '',
  color: '',
  loading: false
}

export default EventCard
