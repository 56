import React, { useState } from 'react'
import { authService } from '@services'
import { Button, Typography } from 'antd'
import { Container, Footer, MainContent } from '@components'
import PropTypes from 'prop-types'
import './Page.less'

const Page = ({ children, className, currentEventId, hero, isGuest, showFooter }) => {
  const isImpersonating = authService.isImpersonating()
  const impersonatedUser = authService.getImpersonatedUser()
  const [isLoggingOut, setIsLoggingOut] = useState(false)

  const logout = () => {
    setIsLoggingOut(true)

    setTimeout(function () {
      authService.logout()
    }, 1000)
  }

  return (
    <div className={`sb-page ${className}`}>
      {isImpersonating && (
        <div className='sb-page__impersonate'>
          <Container className='sb-page__impersonate-container'>
            <Typography.Paragraph>
              You are currently logged in as: <span className='sb-page__impersonate-user'>{impersonatedUser}</span>
            </Typography.Paragraph>

            <Button
              className='sb-page__logout'
              type='primary'
              shape='round'
              loading={isLoggingOut}
              onClick={() => logout()}
            >
              Log out
            </Button>
          </Container>
        </div>
      )}

      {hero && hero}

      <MainContent className='sb-page__content'>{children}</MainContent>

      {showFooter && <Footer currentEventId={currentEventId} isGuest={isGuest} />}
    </div>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  currentEventId: PropTypes.string,
  hero: PropTypes.node,
  isGuest: PropTypes.bool,
  showFooter: PropTypes.bool
}

Page.defaultProps = {
  className: '',
  currentEventId: null,
  hero: null,
  isGuest: false,
  showFooter: true
}

export default Page
