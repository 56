import { gql } from '@apollo/client'

export const NOTIFICATION_FIELDS_FRAGMENT = gql`
  fragment NotificationFields on NotificationRecipient {
    id
    read
    created_at

    event {
      id
      end_date
      title
      client {
        id
        name
      }
    }

    notification {
      id
      title
      description
      active
      created_at

      status {
        id
        name
        code
      }

      type {
        id
        name
        code
      }

      recipientType {
        id
        name
        code
      }
    }
  }
`
