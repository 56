import { gql } from '@apollo/client'

export const ME_UPDATE_DETAILS_MUTATION = gql`
  mutation($input: UpdateMeInput!) {
    updateMe(input: $input) {
      id
      name
      mobile
      email
    }
  }
`

export const ME_UPDATE_PASSWORD_MUTATION = gql`
  mutation($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      id
      name
      mobile
      email
    }
  }
`
