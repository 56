import { gql } from '@apollo/client'
import {
  PARENTS_MEETING_GUEST_BOOKING_FIELDS_FRAGMENT,
  PARENTS_MEETING_GUEST_EVENT_FIELDS_FRAGMENT
} from '../fragments'

const PARENTS_MEETING_GUEST_BOOKING_QUERY = gql`
  ${PARENTS_MEETING_GUEST_BOOKING_FIELDS_FRAGMENT}
  ${PARENTS_MEETING_GUEST_EVENT_FIELDS_FRAGMENT}

  query ($token: String!) {
    guestEvent(token: $token) {
      ...GuestEventFields

      bookings {
        ...GuestBookingFields
      }
    }

    guestInvitation(token: $token) {
      id
      email
      name
      user {
        name
      }
    }
  }
`

export default PARENTS_MEETING_GUEST_BOOKING_QUERY
