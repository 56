import React, { useContext, useState } from 'react'
import { Badge, Card, SimpleCard } from '@components'
import { Button, Input, notification, Space, Switch, Typography } from 'antd'
import { EmptyMessage } from '@components'
import { CheckCircleOutlined, InfoCircleOutlined, MailOutlined, WarningOutlined } from '@ant-design/icons'
import { ReactComponent as Notification } from '@assets/notification.svg'
import { toDayJs, getHumanTimeDuration } from '@helpers'
import { UserContext } from '@context'
import _orderBy from 'lodash/orderBy'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import './NotificationList.less'

const { error: errorNotification } = notification
const { Search } = Input
const { Paragraph, Text, Title } = Typography

const NotificationList = ({ cardColor, event, loading, notifications, showAllNotificationsButton, type }) => {
  const { markAsRead } = useContext(UserContext)
  const [filter, setFilter] = useState(null)
  const [readProcessing, setReadProcessing] = useState([])
  const [showUnreadOnly, setShowUnreadOnly] = useState(false)

  /**
   * Mark notification as read and show loading spinner for the selected notification
   */
  const handleMarkAsRead = notification => {
    setReadProcessing([...readProcessing, notification.id])

    markAsRead(notification)
      .catch(err => {
        errorNotification({
          message: 'Error',
          description: `There was an error marking this notification as read. Please try again.`
        })
      })
      .finally(() => {
        setReadProcessing(readProcessing.filter(notificationId => notificationId !== notification.id))
      })
  }

  const handleClearSearch = () => {
    setFilter('')
  }

  /**
   * Shows alert, info or email icon
   */
  const getAvatar = myNotification => {
    if (myNotification.notification.type.code === 'EMAIL') {
      return <MailOutlined />
    }

    if (getStatus(myNotification) === 'warning') {
      return <WarningOutlined />
    }

    return <InfoCircleOutlined />
  }

  /**
   * Apply search input and unread switch filters
   */
  const getFilteredOrderedNotifications = () => {
    const filteredNotifications = notifications.filter(myNotification => {
      if (showUnreadOnly && myNotification.read) {
        return false
      }

      if (filter) {
        return (
          myNotification.event.id.toLowerCase().includes(filter) ||
          myNotification.event.title.toLowerCase().includes(filter) ||
          myNotification.notification.title.toLowerCase().includes(filter) ||
          myNotification.notification.description.toLowerCase().includes(filter)
        )
      }

      return true
    })

    return _orderBy(filteredNotifications, ['created_at'], ['desc'])
  }

  /**
   * Convert notification status to icon value
   */
  const getStatus = myNotification => (myNotification.notification.status.code === 'CRITICAL' ? 'danger' : 'info')

  /**
   * Notification title
   */
  const getNotificationTitle = myNotification => (
    <div className='sb-notifications__title'>
      {getAvatar(myNotification)}
      {myNotification.notification.title}
    </div>
  )

  /**
   * Count of unread notifications
   */
  const getUnreadNotificationCount = () =>
    notifications.reduce((unreadCount, notification) => {
      if (!notification.read) unreadCount++
      return unreadCount
    }, 0)

  const myNotifications = getFilteredOrderedNotifications()
  const unreadNotificationCount = getUnreadNotificationCount()

  const title = type === 'all' ? 'All Notifications' : 'Event Notifications'

  const strapLine =
    type === 'all' ? 'All future notifications will appear here.' : 'All notifications for this event will appear here.'

  const emptyMessageText =
    type === 'all'
      ? 'Any communications from your schools will appear here.'
      : 'Any future communications from the event organiser will appear here.'

  const emptyMessageTitle =
    type === 'all' ? 'You currently have no notifications' : 'You currently have no notifications for this event'

  /**
   * Render empty notification placeholder if no notifications are present
   */
  if (notifications.length === 0 && !loading) {
    return (
      <SimpleCard color='quaternary' className='sb-notifications' title={title} strapline={strapLine}>
        <EmptyMessage
          title={emptyMessageTitle}
          image={<Notification />}
          description={
            <div>
              <p>{emptyMessageText}</p>

              {showAllNotificationsButton && (
                <div>
                  <Button type='primary' className='cta' shape='round' size='large'>
                    <Link to='/notifications'>View notifications for all events</Link>
                  </Button>
                </div>
              )}
            </div>
          }
        />
      </SimpleCard>
    )
  }

  return (
    <SimpleCard
      className='sb-notifications'
      color={cardColor}
      title={title}
      badge={
        <Badge size='medium' type={unreadNotificationCount === 0 ? 'success' : 'info'}>
          {unreadNotificationCount} Unread
        </Badge>
      }
      strapline='View your notifications for all your events below.'
    >
      <Title className='sb-notifications__search-label' level={5}>
        Search notifications
      </Title>

      <div className='sb-notifications__search'>
        <Search
          placeholder='Filter by keyword'
          allowClear
          size='large'
          onChange={e => setFilter(e.target.value.toLowerCase())}
          value={filter}
        />

        <Space className='sb-notifications__unread-switch' align='center'>
          <Switch checked={showUnreadOnly} onChange={() => setShowUnreadOnly(!showUnreadOnly)} />
          <Paragraph>Unread only</Paragraph>
        </Space>
      </div>

      {loading && (
        <div className='sb-notifications__skeleton'>
          <Card loading />
          <Card loading />
          <Card loading />
        </div>
      )}

      {!loading &&
        myNotifications.length > 0 &&
        myNotifications.map(myNotification => {
          const status = getStatus(myNotification)

          return (
            <Card
              key={myNotification.id}
              className={`sb-notifications__message ${myNotification.read ? 'sb-notifications__message--read' : ''}`}
              title={getNotificationTitle(myNotification)}
              type={status}
              headerActions={
                !loading &&
                (myNotification.read ? (
                  <React.Fragment>
                    <CheckCircleOutlined /> <Paragraph className='hidden-xs'>Read</Paragraph>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Switch
                      className='sb-notifications__mark-read'
                      disabled={myNotification.read}
                      checked={myNotification.read}
                      onChange={() => handleMarkAsRead(myNotification)}
                      loading={readProcessing.includes(myNotification.id)}
                    />

                    <Paragraph className='hidden-xs sb-notifications__mark-read-label'>Mark as Read</Paragraph>
                  </React.Fragment>
                ))
              }
            >
              <div className='sb-notifications__date'>
                <Text strong>{toDayJs(myNotification.created_at).format('dddd DD MMMM YYYY H:mm')}</Text>
                <Badge type={status}>{getHumanTimeDuration(myNotification.created_at)} ago</Badge>
              </div>

              {type === 'all' && (
                <Title className='sb-notifications__event-title' level={5}>
                  {myNotification.event.client.name} - {myNotification.event.title}
                </Title>
              )}

              <Paragraph>{myNotification.notification.description}</Paragraph>
            </Card>
          )
        })}

      {!loading && myNotifications.length === 0 && (
        <EmptyMessage
          title='No notifications could be found!'
          image={<Notification />}
          description={
            <div>
              <Paragraph>Try adjusting your filters or toggling the unread switch.</Paragraph>

              <Button type='primary' shape='round' size='large' onClick={() => handleClearSearch()}>
                Clear Search Filters
              </Button>
            </div>
          }
        />
      )}
    </SimpleCard>
  )
}

NotificationList.propTypes = {
  cardColor: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  notifications: PropTypes.array.isRequired,
  showAllNotificationsButton: PropTypes.bool,
  type: PropTypes.oneOf(['all', 'event_only']).isRequired
}

NotificationList.defaultProps = {
  cardColor: 'quaternary',
  showAllNotificationsButton: false
}

export default NotificationList
