import { getClient, getPersistor } from '@services'

const auth = {
  clearToken: () => {
    localStorage.removeItem('token')
  },

  isImpersonating: () => {
    const payload = auth.getPayload()
    return payload && payload.impersonating === true ? true : false
  },

  getImpersonatedUser: () => {
    const payload = auth.getPayload()
    return payload && payload.impersonated_user ? payload.impersonated_user : null
  },

  getPayload: () => {
    const token = localStorage.getItem('token')

    if (!token) return null

    return JSON.parse(atob(token.split('.')[1]))
  },

  setToken: (token, redirectTo) => {
    localStorage.setItem('token', token)

    if (redirectTo) {
      window.location.href = redirectTo
    } else {
      window.location.href = '/parentsmeeting'
    }
  },

  isAuthenticated: () => {
    return localStorage.getItem('token') !== null
  },

  logout: async () => {
    const client = await getClient()

    client.clearStore()

    const persistor = getPersistor()

    persistor.purge()

    auth.clearToken()

    window.location.href = '/'
  }
}

export default auth
