import { gql } from '@apollo/client'

export const ATTENDANCE_BOOKING_QUERY = gql`
  query($token: String!) {
    attendanceBooking(token: $token) {
      id
      not_attending

      event {
        id
        title
        description
        start_date
        end_date
      }
    }
  }
`
