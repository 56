import React from 'react'
import { Typography } from 'antd'
import { CheckCircleOutlined, InfoCircleOutlined, StopOutlined, WarningOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import './Alert.less'

const { Text, Title } = Typography

const iconsByType = {
  danger: <StopOutlined />,
  info: <InfoCircleOutlined />,
  success: <CheckCircleOutlined />,
  warning: <WarningOutlined />
}

const Alert = ({ children, className, message, title, type }) => (
  <div className={`sb-alert sb-alert--${type} ${className}`}>
    <div className='sb-alert__icon'>{iconsByType[type]}</div>

    <div className='sb-alert__body'>
      <Title className='sb-alert__title'>{title}</Title>
      <Text className='sb-alert__message'>{message}</Text>
    </div>

    {children && <div className='sb-alert__right'>{children}</div>}
  </div>
)

Alert.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['danger', 'info', 'success', 'warning']).isRequired
}

Alert.defaultProps = {
  children: null,
  className: ''
}

export default Alert
