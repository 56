import React, { useContext } from 'react'
import { RoundBadge } from '@components'
import { Avatar, Dropdown, Menu } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import _get from 'lodash/get'
import { UserContext } from '@context'
import { getMenuItems } from './data.source.js'
import './UserMenu.less'

const menu = ({ unreadNotificationCount }) => (
  <Menu className='user-menu-content sb-custom-menu' triggerSubMenuAction='click'>
    {getMenuItems(unreadNotificationCount).map(item => (
      <React.Fragment key={item.key}>
        {item.divider && <Menu.Divider />}
        <Menu.Item key={item.key} icon={item.icon}>
          <Link onClick={() => item.handleClick()} to={item.href}>
            {item.caption}
          </Link>
        </Menu.Item>
      </React.Fragment>
    ))}
  </Menu>
)

const UserMenu = () => {
  const { user } = useContext(UserContext)
  const notificationCount = _get(user, 'unreadNotificationCount', 0)

  return (
    <div className='sb-user-menu'>
      <Dropdown trigger={['click']} overlay={menu(notificationCount)} arrow={true}>
        <span className='avatar-item'>
          <Avatar icon={<UserOutlined style={{ fontSize: 24, cursor: 'pointer' }} />} size='large' />
          <RoundBadge type='warning'>{notificationCount}</RoundBadge>
        </span>
      </Dropdown>
    </div>
  )
}

export default UserMenu
